import React, { Dispatch, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Header, Button } from "components";
import { UserService } from "services/UserService";
import { Reporter, User, UserType } from "types";
import ProfileIcon from "components/atoms/Icons/ProfileIcon";
import TextInput from "components/atoms/TextInput";
import ValidatedInput from "components/organisms/ValidatedInput";

export type ProfileProps = { setSignedIn: Dispatch<boolean> };

const Profile: React.FC<ProfileProps> = ({ setSignedIn }) => {
  let history = useHistory();
  const [editing, toggleEdit] = useState(false);

  const [user, setUser] = useState<User | Reporter>();
  const [newName, setNewName] = useState<string | undefined>();
  const [newEmail, setNewEmail] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState<string | undefined>("");
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [newPasswordCheck, setNewPasswordCheck] = useState<string | undefined>("");
  const [validPasswordCheck, setValidPasswordCheck] = useState<boolean>(false);
  const [renderPasswordCheck, toggleRenderPasswordCheck] = useState<boolean>(false);

  const fetchUser = async () => {
    setUser(await UserService.fetchUser());
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleUpdate = async () => {
    setNewPassword("");
    const data = await UserService.update(newName, newEmail, newPassword);
    setUser(data);
  };

  const handleLogout = async () => {
    UserService.logout().then(() => {
      setSignedIn(false);
      history.push("/");
    });
  };

  const profileInfoMap = () => ({
    [UserType.Grocery]: [
      { label: "Store Operator Name", value: user?.store?.storeOperatorName },
      { label: "Store Operator Number", value: user?.store?.storeOperatorNumber },
      { label: "Telephone Number", value: user?.store?.phoneNumber },
      { label: "Store Address", value: user?.store?.address },
      { label: "Store City", value: user?.store?.city },
      { label: "Store Postal Code", value: user?.store?.postalCode },
    ],
    [UserType.GrocerReporter]: [
      { label: "Channel #", value: (user as Reporter)?.channels?.map(({ id }) => id).join(", ") },
      {
        label: "Channels",
        value: (user as Reporter)?.channels?.map(({ channelName }) => channelName).join(", "),
      },
      { label: "Role", value: "Grocer Reporter" },
    ],
  });

  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        <Header
          variant="profile"
          onEditProfile={() => {
            setNewPassword("");
            toggleEdit(!editing);
          }}
          onBack={() => {
            history.push("/");
          }}
          editing={editing}
        />
        <div className="profile">
          {editing ? (
            <div className="edit">
              <div className="icon">
                <ProfileIcon />
              </div>
              <div className="title">Edit Profile</div>
              <div className="section">
                <h6>PROFILE</h6>
                <div className="content">
                  <TextInput
                    label="Name"
                    disabled
                    defaultValue={user ? user.contactName : ""}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </div>
                <div className="content">
                  <TextInput
                    label="Email"
                    disabled
                    defaultValue={user ? user.email : ""}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="section">
                <h6>PASSWORD</h6>
                <div className="content">
                  <ValidatedInput
                    label="New Password"
                    type="password"
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setNewPasswordCheck("");
                      toggleRenderPasswordCheck(!renderPasswordCheck);
                    }}
                    validations={[
                      {
                        condition: (value: string) => `${value}`.length >= 6,
                        label: "Must be at least 6 characters.",
                      },
                      {
                        condition: (value: string) => /\d/.test(value),
                        label: "Must contain at least 1 number.",
                      },
                    ]}
                    value={newPassword}
                    onCheck={setValidPassword}
                  />
                </div>
                <div className="content">
                  <ValidatedInput
                    label="Re-Enter New Password"
                    type="password"
                    onChange={(e) => setNewPasswordCheck(e.target.value)}
                    validations={[
                      {
                        condition: (value: string) =>
                          value === newPassword && newPassword.length > 0,
                        label: "Both passwords must match.",
                      },
                    ]}
                    onCheck={setValidPasswordCheck}
                    triggerCheck={[renderPasswordCheck]}
                  />
                </div>
              </div>
              <div className="button-container">
                <Button
                  onClick={() => {
                    handleUpdate();
                    toggleEdit(false);
                  }}
                  styling="next-btn"
                  disabled={
                    !(
                      (validPassword && validPasswordCheck) ||
                      (newPassword.length === 0 && newPasswordCheck.length === 0)
                    )
                  }
                >
                  Update
                </Button>
                <Button
                  onClick={() => {
                    toggleEdit(false);
                  }}
                  styling="back-btn"
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="title">
                <div className="icon">
                  <ProfileIcon />
                </div>
                <div className="name">{user ? user.contactName : null}</div>
                <div className="email">{user ? user.email : null}</div>
              </div>
              <div className="body">
                <div className="store-info">
                  {user
                    ? user.type === UserType.GrocerReporter
                      ? "My Report Channels"
                      : "My Store Info"
                    : "Loading..."}
                </div>
                {user &&
                  profileInfoMap()[user.type].map(({ label, value }) => (
                    <>
                      <div className="content-title">{label}</div>
                      <div className="content">{value}</div>
                    </>
                  ))}
              </div>
              <div className="logout">
                <div className="logout-button">
                  <Button
                    onClick={() => {
                      handleLogout();
                    }}
                    styling="next-btn"
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
