import * as React from "react";
import { ToastState } from "../../types";

interface ToastMessage {
  message: string;
}

export default class Toast extends React.Component<
  {},
  {
    message: string;
    state: ToastState;
    visible: boolean;
  }
> {
  private static el: Toast;
  private timeout: NodeJS.Timer | number | null = null;

  constructor(props: {}) {
    super(props);
    this.state = {
      message: "",
      state: ToastState.DEFAULT,
      visible: false,
    };
    Toast.el = this;
  }

  public static throw = ({ message }: ToastMessage) => {
    Toast.el?.show(message, ToastState.ERROR);
  };

  public static present = ({ message }: ToastMessage, state: ToastState) => {
    Toast.el?.show(message, state);
  };

  show = (message: string, state: ToastState) => {
    this.setState({ message, state, visible: true });
    if (this.timeout != null) clearTimeout(this.timeout as number);
    this.timeout = setTimeout(() => {
      this.setState({ visible: false });
    }, 5000);
  };

  render() {
    const { message, state, visible } = this.state;
    return <div className={`toast ${state} ${visible ? "visible" : ""}`}>{message}</div>;
  }
}
