import * as React from "react";

function SvgClearInputIcon({onClick}) {
  return (
    <div className="clear-input" onClick={onClick}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9.99967" cy="9.99967" r="9.16667" fill="#272727" />
        <path
          d="M13.9815 6.0242C13.735 5.77771 13.3368 5.77771 13.0903 6.0242L9.99967 9.10851L6.90904 6.01788C6.66255 5.77138 6.26437 5.77138 6.01788 6.01788C5.77138 6.26437 5.77138 6.66255 6.01788 6.90904L9.10851 9.99967L6.01788 13.0903C5.77138 13.3368 5.77138 13.735 6.01788 13.9815C6.26437 14.228 6.66255 14.228 6.90904 13.9815L9.99967 10.8908L13.0903 13.9815C13.3368 14.228 13.735 14.228 13.9815 13.9815C14.228 13.735 14.228 13.3368 13.9815 13.0903L10.8908 9.99967L13.9815 6.90904C14.2216 6.66887 14.2216 6.26437 13.9815 6.0242Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default SvgClearInputIcon;
