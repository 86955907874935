import * as React from "react";

function SvgRefundIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color ? props.color : "white";
  return (
    <svg
      width="43"
      height="47"
      viewBox="0 0 43 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.3587 3.82308C33.8472 4.14318 43 13.5618 43 25.0585C43 36.7907 33.4886 46.3021 21.7558 46.3021C11.792 46.3021 3.12843 39.3427 1.00203 29.604C0.457458 27.0799 4.29454 26.252 4.83911 28.7755C6.57428 36.7219 13.6286 42.3734 21.7558 42.3734C31.3191 42.3734 39.0708 34.6212 39.0708 25.0585C39.0708 15.732 31.6765 8.07354 22.3587 7.75403V11.0856C22.3587 12.3572 20.9326 13.0826 19.9064 12.3876C17.1929 10.8215 14.4805 9.25131 11.7664 7.68406C10.7035 7.07361 10.7361 5.54659 11.781 4.96237C14.5236 3.37879 17.2593 1.79638 20.0014 0.213391C21.0562 -0.39765 22.357 0.380727 22.3552 1.56957L22.3587 3.82308ZM14.6338 28.6344L17.9613 28.3044C18.3397 30.4039 19.451 31.5362 21.6381 31.5362C23.0759 31.5362 24.9446 30.9538 24.9446 29.2128C24.9446 27.4 22.024 27.0659 20.655 26.7225C17.9584 26.0479 15.2717 24.8638 15.2717 21.6348C15.2717 18.7504 17.5304 17.375 20.0067 17.01V14.8725H22.9937V17.0164C25.6885 17.4059 27.759 18.8915 27.8709 22.0756L24.4501 22.1928C24.1452 20.3696 23.1721 19.7393 21.3745 19.7393C20.3338 19.7393 18.5269 20.0506 18.5269 21.4068C18.5269 22.8668 21.104 23.3181 22.1605 23.5694C25.4413 24.3408 28.3659 25.3139 28.3659 29.1929C28.3659 31.2354 27.1631 33.0143 25.2787 33.7979C24.6227 34.0673 23.8618 34.2538 22.9937 34.3465V36.4502H20.0067V34.3092C16.9719 33.8801 15.0496 32.1193 14.6338 28.6344ZM2.16581 20.8424C3.5284 20.664 4.77789 21.6237 4.95572 22.9863C5.13355 24.3489 4.17385 25.5972 2.81183 25.7762C1.44923 25.9546 0.200332 24.9949 0.021334 23.6318C-0.157081 22.2697 0.80321 21.0208 2.16581 20.8424ZM4.80296 14.3204C6.1434 14.6189 6.99 15.9483 6.69089 17.2893C6.39237 18.6309 5.06359 19.4757 3.7214 19.1778C2.38095 18.8799 1.53553 17.5505 1.83405 16.2089C2.13141 14.8673 3.46194 14.0219 4.80296 14.3204ZM9.51112 9.0933C10.6691 9.83262 11.009 11.3707 10.2703 12.5298C9.53036 13.6878 7.99285 14.0277 6.83432 13.2884C5.67638 12.5496 5.33645 11.011 6.07518 9.85244C6.81391 8.6945 8.35259 8.35458 9.51112 9.0933Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgRefundIcon;
