import * as React from "react";

function SvgRecallIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color ? props.color : "white";
  return (
    <svg
      width="43"
      height="47"
      viewBox="0 0 43 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.4095 3.82308C32.898 4.14318 42.0507 13.5618 42.0507 25.0585C42.0507 36.7907 32.5394 46.3021 20.8066 46.3021C10.8428 46.3021 2.17917 39.3427 0.0527723 29.604C-0.4918 27.0799 3.34528 26.252 3.88985 28.7755C5.62502 36.7219 12.6794 42.3734 20.8066 42.3734C30.3698 42.3734 38.1215 34.6212 38.1215 25.0585C38.1215 15.732 30.7272 8.07354 21.4095 7.75403V11.0856C21.4095 12.3572 19.9833 13.0826 18.9571 12.3876C16.2436 10.8215 13.5312 9.25131 10.8171 7.68406C9.75421 7.07361 9.78686 5.54659 10.8317 4.96237C13.5744 3.37879 16.3101 1.79638 19.0522 0.213391C20.1069 -0.39765 21.4077 0.380727 21.406 1.56957L21.4095 3.82308Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5588 21.0746C27.118 20.8728 26.8753 20.5489 26.8753 20.1622V15.1657H27.1157V14.4568L26.5047 14H24.4621L23.8511 14.4568V15.1659H24.0923V20.1625C24.0923 20.5492 23.8496 20.8733 23.4085 21.0749C22.4727 21.5038 21.9141 22.2472 21.9141 23.0637V26.8377V27.087V35.7126C21.9141 36.5046 23.5152 37.1487 25.4831 37.1487C27.4514 37.1487 29.0524 36.5046 29.0524 35.7126V27.087V26.8377V23.0637C29.0528 22.2472 28.4942 21.5035 27.5588 21.0746Z"
        fill={color}
      />
      <path
        d="M18.012 20.1622C18.012 20.5489 18.2547 20.8728 18.6955 21.0746C19.6309 21.5035 20.1895 22.2472 20.1892 23.0637V27.087L18.6955 28.0785L17.5123 26.393L16.5208 27.5828L15.2291 26.8887L13.9431 28.5742L13.0508 27.087V23.0637C13.0508 22.2472 13.6094 21.5038 14.5452 21.0749C14.9864 20.8733 15.2291 20.5492 15.2291 20.1625V15.1659H14.9878V14.4568L15.5988 14H17.6415L18.2525 14.4568V15.1657H18.012V20.1622Z"
        fill={color}
      />
      <path
        d="M16.6198 37.1486C14.6519 37.1486 13.0508 36.5044 13.0508 35.7124V26.8376L13.9431 28.3247L15.2291 26.6393L16.5208 27.3333L17.5123 26.1436L18.6955 27.829L20.1892 26.8376V35.7124C20.1892 36.5044 18.5881 37.1486 16.6198 37.1486Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgRecallIcon;
