import React from "react";
import { Button as BaseButton, ButtonProps as BaseButtonProps } from "react-bootstrap";

type CustomButtonProps = {
  styling?: string;
};

export type ButtonProps = BaseButtonProps & CustomButtonProps;

/*
There's base styling common for all buttons in this application.
Pass in styling as a prop for additional styling (background color, color, border, etc.) 
Remember to add in the scss selector into Button.scss at the same level as the other custom stylings.
*/
export const Button: React.FC<ButtonProps> = ({ styling: styling, ...rest }) => {
  return (
    <div className="custom-button">
      <BaseButton className={`${styling ? styling : ""}`} {...rest} />
    </div>
  );
};
