import { Dispatch, SetStateAction } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Home from "./pages/Home";
import ReporterHome from "./pages/ReporterHome";
import Claim from "./pages/Claim";
import ClaimStatus from "pages/Claim/ClaimStatus";
import Profile from "./pages/Profile";

export type PlatformProps = {
  signedIn: Boolean;
  setSignedIn: Dispatch<SetStateAction<Boolean>>;
};

const Platform: React.FC<PlatformProps> = ({ signedIn, setSignedIn }) => {
  const toggleSignedIn = (val: boolean) => {
    setSignedIn(val);
  };

  return (
    <div className="platform">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/profile">
            <Profile setSignedIn={toggleSignedIn} />
          </Route>
          <Route exact path="/claim/:type/:id" component={Claim} />
          <Route exact path="/status/:type/:id" component={ClaimStatus} />
          <Route exact path="/reporter" component={ReporterHome} />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default Platform;
