function SvgNotificationIcon() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.50003 1.08301C9.49219 1.08301 11.9178 3.50859 11.9178 6.50076C11.9178 9.49238 9.49219 11.918 6.50003 11.918C3.50786 11.918 1.08228 9.49238 1.08228 6.50076C1.08228 3.50859 3.50786 1.08301 6.50003 1.08301ZM6.50003 1.89551C5.89109 1.8889 5.28689 2.00314 4.72239 2.2316C4.1579 2.46006 3.64432 2.79821 3.21138 3.22647C2.77845 3.65474 2.43475 4.16462 2.20018 4.7266C1.96561 5.28859 1.84483 5.89151 1.84483 6.50049C1.84483 7.10946 1.96561 7.71239 2.20018 8.27437C2.43475 8.83635 2.77845 9.34623 3.21138 9.7745C3.64432 10.2028 4.1579 10.5409 4.72239 10.7694C5.28689 10.9978 5.89109 11.1121 6.50003 11.1055C7.70904 11.0869 8.86226 10.5936 9.71068 9.73209C10.5591 8.87056 11.0346 7.70991 11.0346 6.50076C11.0346 5.2916 10.5591 4.13096 9.71068 3.26942C8.86226 2.40789 7.70904 1.91459 6.50003 1.89605V1.89551ZM6.49786 5.68717C6.59612 5.68705 6.6911 5.72254 6.7652 5.78708C6.8393 5.85162 6.8875 5.94082 6.90086 6.03818L6.90465 6.09342L6.90628 9.07367C6.90624 9.1766 6.86714 9.27568 6.79687 9.35089C6.7266 9.42611 6.63041 9.47184 6.52772 9.47886C6.42503 9.48587 6.3235 9.45365 6.24366 9.38869C6.16381 9.32374 6.1116 9.23089 6.09757 9.12893L6.09378 9.07422L6.09215 6.09397C6.09215 5.98622 6.13495 5.88289 6.21114 5.8067C6.28732 5.73052 6.39066 5.68772 6.4984 5.68772L6.49786 5.68717ZM6.50003 3.79351C6.57249 3.79121 6.64468 3.80351 6.7123 3.82965C6.77993 3.8558 6.8416 3.89527 6.89368 3.94572C6.94575 3.99617 6.98715 4.05657 7.01542 4.12333C7.0437 4.1901 7.05827 4.26186 7.05827 4.33436C7.05827 4.40686 7.0437 4.47863 7.01542 4.54539C6.98715 4.61215 6.94575 4.67255 6.89368 4.723C6.8416 4.77345 6.77993 4.81292 6.7123 4.83907C6.64468 4.86522 6.57249 4.87751 6.50003 4.87522C6.35954 4.87077 6.22631 4.81184 6.12851 4.71089C6.03071 4.60995 5.97602 4.47491 5.97602 4.33436C5.97602 4.19381 6.03071 4.05877 6.12851 3.95783C6.22631 3.85689 6.35954 3.79795 6.50003 3.79351Z"
        fill="#2A55A2"
      />
    </svg>
  );
}

export default SvgNotificationIcon;
