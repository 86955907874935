import React, { useState, useEffect } from "react";
import { UppyFile } from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./style/ImageUploader.scss";
import pdf_icon from "./assets/pdf-icon.jpg";
import { DashboardModal, useUppy } from "@uppy/react";
import { createUppy } from "services/PhotoService";
import DeleteImageIcon from "./Icons/DeleteImageIcon";
import ImageModal from "./ImageModal";

export type ImageUploaderProps = {
  id: string; // Unique id for each Uppy instance
  disableChanges: boolean;
  images: string[]; // List of image URLs to display as default
  onUpload: (files: UppyFile[]) => void; // Uppy file objects passed when modal closed
  onDelete: (imageURL: string) => void;
};

const ImageUploader: React.FC<ImageUploaderProps> = ({
  id,
  disableChanges,
  images,
  onUpload,
  onDelete,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const uppy = useUppy(() => {
    return createUppy();
  });
  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  // returns if a given string from the images prop is a pdf
  const isPDF = (image: string) => {
    return image.substring(0, 3) === "pdf";
  };

  const parsePDFString = (image) => {
    let [filename, url] = image.substring(4).split(" |<break>|");
    if (filename.length > 17) {
      filename = filename.substring(0, 16) + "...";
    }
    return [filename, url];
  };

  const viewPDF = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const alreadyUploaded = (toUpload: UppyFile) => {
    // if pdf, have to compare filename rather than blob name
    if (toUpload.type === "application/pdf") {
      const pdfName = toUpload.name;
      for (let image of images) {
        if (isPDF(image) && image.includes(pdfName)) return true;
      }
      return false;
    }
    return images.includes(toUpload.preview);
  };

  useEffect(() => {
    const uploadHandler = () => {
      let newImages = uppy.getFiles();
      const newImageURLs = [];
      newImages = newImages.filter((newImage) => {
        if (alreadyUploaded(newImage) || newImageURLs.includes(newImage.preview)) {
          return false;
        } else {
          newImageURLs.push(newImage);
          return true;
        }
      });
      onUpload(newImages);
      setModalOpen(false);
    };

    uppy.on("upload", uploadHandler);

    return () => {
      uppy.off("upload", uploadHandler);
    };
  });

  useEffect(() => {
    const removeHandler = (file: UppyFile) => {
      // if pdf, have to find filename rather than blob name
      if (file.type === "application/pdf") {
        const pdfName = file.name;
        for (let image of images) {
          if (isPDF(image) && image.includes(pdfName)) {
            onDelete(image);
          }
        }
      } else {
        onDelete(file.preview);
      }
    };

    uppy.on("file-removed", removeHandler);

    return () => {
      uppy.off("file-removed", removeHandler);
    };
  });

  return (
    <div className="images">
      <div className="thumbnails">
        {images.length > 0 ? (
          images.map((image) => (
            <div key={image} className="image-container">
              {image.substring(0, 3) === "pdf" ? (
                <>
                  <img
                    className="image"
                    src={pdf_icon}
                    alt={"Uploaded PDF"}
                    onClick={() => viewPDF(parsePDFString(image)[1])}
                  />
                  <div className="filename">{parsePDFString(image)[0]}</div>
                </>
              ) : (
                <img
                  className="image"
                  src={image}
                  alt={"Upload"}
                  onClick={() => {
                    setModalImage(image);
                    setModalVisible(true);
                  }}
                />
              )}
              {disableChanges ? null : (
                <div className="delete" onClick={() => onDelete(image)}>
                  <DeleteImageIcon />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="empty">Photo Thumbnails</div>
        )}
      </div>
      <ImageModal isVisible={modalVisible} setVisible={setModalVisible} image={modalImage} />
      <div className="upload">
        {disableChanges ? null : (
          <button className="open-modal-btn" onClick={handleOpen}>
            Upload Images
          </button>
        )}
        <DashboardModal
          uppy={uppy}
          proudlyDisplayPoweredByUppy={false}
          open={modalOpen}
          onRequestClose={handleClose}
          closeModalOnClickOutside
        />
      </div>
    </div>
  );
};

export default ImageUploader;
