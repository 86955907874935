import React from "react";
import { Modal as BaseModal } from "react-bootstrap";

export type ModalProps = {
  handleClose?: () => void;
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps & Omit<any, "handleClose" | "children">> = (props) => {
  return (
    <BaseModal centered onHide={props.handleClose} {...props}>
      <BaseModal.Body>{props.children}</BaseModal.Body>
    </BaseModal>
  );
};

export default Modal;
