import { Button } from "components";
import Modal from "components/organisms/Modal";
import { useHistory } from "react-router-dom";
import { submitClaim } from "services/ClaimService";
import { ClaimType } from "types";
import { ClaimComponentProps } from ".";

export type SubmitModalProps = {
  type: "refund" | "adjustment" | "recall_pr" | "destroy" | "return";
  id: string;
  back: () => void;
} & ClaimComponentProps;

const SubmitModal: React.FC<SubmitModalProps> = ({ type, id, back }) => {
  const history = useHistory();
  const TEXT_MAP = {
    refund: {
      title: "You are about to submit a Return/Refund Claim.",
      body: [
        <>
          <strong>
            Be sure to double check that the item information entered is correct and accurate.
          </strong>
        </>,
        <>
          Once submitted, be sure to check back on the status of your claim and for important
          communication or information we may request from you.
        </>,
        <>
          Pickup instructions will be provided, if applicable. Check back soon for comments in your
          claim.
        </>,
      ],
    },
    adjustment: {
      title: "You are about to submit an Adjustment Claim.",
      body: [
        <>
          <strong>
            Be sure to double check that the item information entered is correct and accurate.
          </strong>
        </>,
        <>
          Be sure to check back on the status of your claim and for any comments requesting
          information.
        </>,
      ],
    },
    recall_pr: {
      title: "You are about to submit a Wine Floor Price Product Recall.",
      body: [
        <>
          <strong>
            Be sure to double check that the item information entered is correct and accurate.
          </strong>
        </>,
        <>
          Once submitted, be sure to check back on the status of your claim and for important
          communication or information we may request from you.
        </>,
        <>Pickup instructions will be provided. Check back soon for comments in your claim.</>,
      ],
    },
    destroy: {
      title: "You are about to submit a QA Alert Product Recall Destroy Claim.",
      body: [
        <>
          <strong>
            Do NOT destroy any product until this claim request is approved by the LCBO.
          </strong>
        </>,
        <>
          Your claim request will be reviewed within 2 business days.
        </>,
        <>Product destroyed without approval may not be credited.</>,
      ],
    },
    return: {
      title: "You are about to submit a QA Alert Product Recall Return Claim.",
      body: [
        <>
          <div className="highlight">NOTE:</div> QA Recall product is <strong>NOT</strong> returned
          to LCBO warehouse.
        </>,
        <>Product will be picked up by the LCBO’s contracted courier.</>,
        <>
          Monitor your email for a message from <strong>ProductRecall@LCBOReturn.com</strong>, which
          will include your Return Authorization and instructions to arrange for the product to be
          picked up.
        </>,
      ],
    },
  };
  return (
    <>
      <Modal
        handleClose={() => {
          back();
        }}
        show
      >
        <div className="modal-warning">
          <div className="title">{TEXT_MAP[type].title}</div>
          {TEXT_MAP[type].body.map((el) => {
            return <div className="body">{el}</div>;
          })}
          <div className="button-container">
            <Button
              onClick={() => {
                submitClaim(
                  parseInt(id),
                  ["refund", "adjustment"].includes(type)
                    ? ClaimType[type[0].toUpperCase() + type.slice(1)]
                    : "recall"
                )
                  .then(() => {
                    history.push("/");
                  })
                  .catch((err) => {
                    if (err.message === "Grocer submit submitted claim") {
                      history.push("/");
                    } else {
                      back();
                    }
                  });
              }}
              styling="next-btn small"
            >
              <div className="padding">Submit Form</div>
            </Button>
            <Button
              onClick={() => {
                back();
              }}
              styling="back-btn small"
            >
              Return to Form
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubmitModal;
