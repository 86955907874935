import React from "react";
import { Modal } from "react-bootstrap";

export type ImageModalProps = {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
  image: string;
};

const ImageModal: React.FC<ImageModalProps> = ({ isVisible, setVisible, image }) => {
  return (
    <Modal
      show={isVisible}
      onHide={() => setVisible(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <img src={image} alt="Modal" />
    </Modal>
  );
};

export default ImageModal;
