function SvgEditItemIcon(props) {
  return props.missing ? (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.455 3.145L13.855 6.545L3.4 17H0V13.6L10.455 3.145ZM11.645 1.955L13.6 0L17 3.4L15.045 5.355L11.645 1.955V1.955Z"
        fill="#B1243C"
      />
    </svg>
  ) : (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.455 3.145L13.855 6.545L3.4 17H0V13.6L10.455 3.145ZM11.645 1.955L13.6 0L17 3.4L15.045 5.355L11.645 1.955Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgEditItemIcon;
