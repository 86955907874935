import React, { WheelEventHandler } from "react";

export type BaseTextInputProps = {
  label: string | React.ReactNode;
  placeholder?: string;
  onChange?: (value: any) => void;
  defaultValue?: string;
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  sizing?: "sm" | "md" | "lg";
  onKeyDown?: (e: any) => void;
  value?: string;
  asterisk?: boolean;
};

export type TextInputProps = BaseTextInputProps &
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder = "",
  onChange,
  defaultValue = "",
  sizing = "md",
  type = "text",
  onKeyDown = () => {},
  asterisk = false,
  ...rest
}) => {
  return (
    <div className="text-input">
      <div className="label">
        {label}
        {asterisk && <div className="asterisk">*</div>}
      </div>
      {sizing === "lg" ? (
        <textarea
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          rows={4}
          {...rest}
        />
      ) : (
        <div className={sizing}>
          <input
            type={type}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            onWheel={() => (document.activeElement as HTMLElement).blur()}
            {...rest}
          />
        </div>
      )}
    </div>
  );
};

export default TextInput;
