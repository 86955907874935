import React from "react";
import { Row, Container, Card } from "react-bootstrap";
import { Item } from "types";
import DeleteItemIcon from "./Icons/DeleteImageIcon";
import EditItemIcon from "./Icons/EditItemIcon";

export type ItemCardProps = Partial<Pick<Item, "itemNumber" | "productDescription">> & {
  index: number;
  missing: boolean;
  onDelete: () => void;
  onEdit: () => void;
  viewOnly?: boolean;
};

const ItemCard: React.FC<ItemCardProps> = ({
  index,
  itemNumber = -1,
  productDescription = `Unnamed Item ${index}`,
  missing,
  onDelete,
  onEdit,
  viewOnly = false,
}) => {
  return (
    <div className="item-card">
      <Container className="flex-grow-1">
        <Row>
          {!viewOnly && (
            <div className="delete-icon-container">
              <div className="delete-icon" onClick={onDelete}>
                <DeleteItemIcon />
              </div>
            </div>
          )}
          <Card className="border-none">
            <div className="container" onClick={onEdit}>
              <div className="blue">{index}</div>
              <div className="body">
                <Card.Title as="h6">{productDescription}</Card.Title>
                <Card.Text>{`Item Number: ${itemNumber >= 0 ? itemNumber : "Unknown"}`}</Card.Text>
                {missing ? <div className="missing-msg">Mandatory Fields Missing</div> : null}
              </div>
              <div className="edit-icon">
                <EditItemIcon missing={missing} />
              </div>
            </div>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default ItemCard;
