import { string, number, date, object } from "yup";

const commentSchema = object({
  id: number().defined(),
  createDate: date().defined(),
  updateDate: date().defined(),
  message: string().defined(),
  status: string().defined().nullable(),
  user: object().defined(),
});

export { commentSchema };
