import React from "react";
import { Row, Container, Card } from "react-bootstrap";
import { Claim, ClaimType } from "types";

export type ReporterClaimCardProps = Pick<
  Claim,
  "id" | "status" | "type" | "updateDate" | "items"
> & {
  onClick: () => void;
};

const ClaimCard: React.FC<ReporterClaimCardProps> = ({
  id,
  status,
  type,
  updateDate,
  items,
  onClick,
}) => {
  const styling = status;
  const statusText = status;
  return (
    <div className="reporter-claim-card">
      <Container className="flex-grow-1">
        <Row>
          <Card className="border-none" onClick={onClick}>
            <Card.Body>
              <Card.Text className="id">{`${type} #${id}`}</Card.Text>
              <Card.Text className={styling}>{statusText}</Card.Text>
              {items.map(({ itemNumber, itemName, productDescription }) => (
                <Card.Title as="h6">{`${itemNumber > 0 ? itemNumber : "Custom"} ${
                  (type === ClaimType.Recall ? itemName : productDescription) || ""
                }`}</Card.Title>
              ))}

              <Card.Text className={"date"}>{`Last Updated: ${updateDate.toFormat(
                "dd LLL yyyy"
              )}`}</Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default ClaimCard;
