import React from "react";
import { Row, Container, Card } from "react-bootstrap";
import { Claim, ClaimStatus, ClaimType, Recall } from "types";
import CommentNotifIcon from "./Icons/CommentNotifIcon";

export type ClaimCardProps = Pick<Claim, "id" | "status" | "type" | "updateDate"> &
  Partial<Pick<Claim, "items">> &
  Partial<Pick<Recall, "alert">> & {
    declinedItems?: boolean;
    notification: boolean;
    onClick: () => void;
  };

const ClaimCard: React.FC<ClaimCardProps> = ({
  id,
  status,
  declinedItems = false,
  items = [],
  alert = null,
  type,
  updateDate,
  notification,
  onClick,
}) => {
  const completedWithDeclined = declinedItems && status === ClaimStatus.Completed;
  const styling = (completedWithDeclined || status === ClaimStatus.Declined) ? "completed_declined" : status;
  const statusText = (completedWithDeclined || status === ClaimStatus.Declined) ? "Completed With Declined Items" : status;
  const isRecall = type === ClaimType.Recall;
  return (
    <div className="claim-card">
      <Container className="flex-grow-1">
        <Row>
          <Card className="border-none" onClick={onClick}>
            <Card.Body className="claim-card-header">
              <Card.Title as="h6">{`${type} #${id}`}</Card.Title>
              <Card.Text className={styling}>{statusText}</Card.Text>
            </Card.Body>
            <Card.Body>
              <Card.Text>
                Item Numbers:{" "}
                {items.length > 0 ? (
                  items.map(({ itemNumber }) => (itemNumber > 0 ? itemNumber : "Custom")).join(", ")
                ) : (
                  <p className="card-missing-text">Missing</p>
                )}
              </Card.Text>
              {alert && <Card.Text>Alert Number: {alert.alertNumber}</Card.Text>}
              <Card.Text>
                {`Last Update: ${updateDate.toFormat("dd LLL yyyy")}`}
                {notification ? (
                  <div className="notif">
                    <CommentNotifIcon />
                  </div>
                ) : null}
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default ClaimCard;
