import { Adjustment, Claim, ClaimStatus, ClaimType, Recall, Refund } from "types/claim";
import { Comment } from "types/comment";
import axios from "./BaseService";
import { DateTime } from "luxon";
import download from "downloadjs";
import {
  refundSchema,
  adjustmentSchema,
  recallSchema,
  typeCheck,
  // refundDraftSchema,
  // adjustmentDraftSchema,
  // recallDraftSchema,
} from "./validation/claimValidators";
import { AnySchema } from "yup";
import { Channel, Store } from "types";

// If lastComment undefined or null, last comment will be extracted from comments. Latest datetime found
// between updateDate and lastComment will be used.
const lastActivity = (updateDate: DateTime, comments: Comment[] = []): DateTime => {
  const commentUpdates: DateTime[] = comments.map((comment) => comment.updateDate);
  let lastCommentUpdate: DateTime | undefined =
    commentUpdates.length > 0 ? DateTime.min(...commentUpdates) : undefined;

  if (lastCommentUpdate !== undefined) {
    return lastCommentUpdate > updateDate ? lastCommentUpdate : updateDate;
  } else {
    return updateDate;
  }
};

// Rename properties, remove extras, retype for yup validation
const preTypeCheck = (claimType: ClaimType, claim: any): any => {
  if (claimType === ClaimType.Refund || claimType === ClaimType.Adjustment) {
    let { createDate, updateDate, notification } = claim;
    createDate = new Date(createDate);
    updateDate = new Date(updateDate);
    notification = notification === null ? false : notification;
    claim.items = claim.items.map((item) => {
      if (claimType === ClaimType.Refund) {
        item.receivalDate = new Date(item.receivalDate);
      }
      item.createDate = new Date(item.createDate);
      item.updateDate = new Date(item.updateDate);
      item.receivalDate = new Date(item.receivalDate);
      return item;
    });
    claim = {
      ...claim,
      type: claimType,
      createDate,
      updateDate,
      notification,
    };
    delete claim.assignedTo;
    delete claim.date;
  } else if (claimType === ClaimType.Recall) {
    let { id, user: storeId, created: createDate, updated: updateDate } = claim;
    id = parseInt(id);
    storeId = storeId?.storeId;
    createDate = new Date(createDate);
    updateDate = new Date(updateDate);
    claim.items = claim.items.map((item) => {
      item.id = parseInt(item.id);
      item.claimId = parseInt(item.claimId);
      item.createDate = new Date(item.created); // different for refund
      item.updateDate = new Date(item.updated);
      item.isApproved = item.approved;
      delete item.created;
      delete item.updated;
      delete item.approved;
      return item;
    });
    claim = { ...claim, type: claimType, id, storeId, createDate, updateDate };
    delete claim.user;
    delete claim.created;
    delete claim.updated;
  }
  if (
    claim.status === ClaimStatus.Pending ||
    claim.status === ClaimStatus.Validating ||
    claim.status === ClaimStatus.Approved ||
    claim.status === ClaimStatus.Pendingqa ||
    claim.status === ClaimStatus.Pendinggrocer
  ) {
    claim.status = ClaimStatus.Submitted;
  }
  claim.comments = claim.comments?.map((comment) => {
    comment.createDate = new Date(comment.createDate);
    comment.updateDate = new Date(comment.updateDate);
    return comment;
  });

  return claim;
};

// Apply Luxon datetimes and assert types
const postTypeCheck = <T extends Claim>(claim: any): T => {
  claim.createDate = DateTime.fromJSDate(claim.createDate);

  claim.items = claim.items.map((item) => {
    if (item.receivalDate) {
      item.receivalDate = DateTime.fromJSDate(item.receivalDate);
    }
    return {
      ...item,
      createDate: DateTime.fromJSDate(item.createDate),
      updateDate: DateTime.fromJSDate(item.updateDate),
    };
  });
  claim.comments = claim.comments
    ?.map((comment) => {
      comment.createDate = DateTime.fromJSDate(comment.createDate);
      comment.updateDate = DateTime.fromJSDate(comment.updateDate);
      return comment;
    })
    .sort((a, b) => a.updateDate.toMillis() - b.updateDate.toMillis());
  claim.updateDate = lastActivity(DateTime.fromJSDate(claim.updateDate), claim.comments);

  return claim as T;
};

// Wrapper function for type check hooks
const applyTypeCheck = async <T extends Claim>(
  claim: T,
  claimType: ClaimType,
  schema: AnySchema
): Promise<T | undefined> => {
  claim = preTypeCheck(claimType, claim);
  claim = await typeCheck(claim, schema);
  if (claim !== undefined) {
    return postTypeCheck<T>(claim);
  } else {
    console.log("undefined");
    return undefined;
  }
};

// Retrieves all claims. Deals with type casting and type checks; all types should be clean outside this function.
export const fetchClaims = async (): Promise<Claim[]> => {
  const refundItemsEndpoint = "/refundsItems";
  const adjustmentItemsEndpoint = "/adjustmentsItems";
  const recallEndpoint = "/alert_claims";

  const [rawRefunds, rawAdjustments, rawPriceRecalls, rawQARecalls] = await Promise.all([
    axios.get(refundItemsEndpoint),
    axios.get(adjustmentItemsEndpoint),
    axios.get(recallEndpoint + "?type=price_recall"),
    axios.get(recallEndpoint + "?type=qa_alert"),
  ]);

  let claims: (Claim | undefined)[] = [];

  const checkedRefunds: (Refund | undefined)[] = await Promise.all(
    rawRefunds.data.map(async (refund) =>
      applyTypeCheck<Refund>(refund, ClaimType.Refund, refundSchema)
    )
  );

  const checkedAdjustments: (Adjustment | undefined)[] = await Promise.all(
    rawAdjustments.data.map(async (adjustment) =>
      applyTypeCheck<Adjustment>(adjustment, ClaimType.Adjustment, adjustmentSchema)
    )
  );

  const checkedRecalls: (Recall | undefined)[] = await Promise.all([
    ...rawPriceRecalls.data.results.map(async (recall) =>
      applyTypeCheck<Recall>(recall, ClaimType.Recall, recallSchema)
    ),
    ...rawQARecalls.data.results.map(async (recall) =>
      applyTypeCheck<Recall>(recall, ClaimType.Recall, recallSchema)
    ),
  ]);
  claims.push(...checkedRefunds, ...checkedAdjustments, ...checkedRecalls);
  claims = claims.filter((claim) => claim !== undefined);
  return claims;
};

export const fetchClaim = async (type: ClaimType, id: number): Promise<Claim> => {
  let data: any = undefined;
  switch (type) {
    case ClaimType.Refund:
      data = (await axios.get(`/refunds/${id}`)).data;
      return applyTypeCheck<Refund>(data, ClaimType.Refund, refundSchema);
    case ClaimType.Adjustment:
      data = (await axios.get(`/adjustments/${id}`)).data;
      return applyTypeCheck<Adjustment>(data, ClaimType.Adjustment, adjustmentSchema);
    case ClaimType.Recall:
      data = (await axios.get(`/alert_claims/${id}`)).data;
      return applyTypeCheck<Recall>(data, ClaimType.Recall, recallSchema);
  }
};

export const createClaim = async (type: ClaimType) => {
  const base = type === ClaimType.Recall ? "alert_claim" : type;
  return axios.post(`/${base}s`).then(async ({ data }) => {
    return data;
  });
};

export const deleteClaim = async (id: Claim["id"], type: ClaimType) => {
  const base = type === ClaimType.Recall ? "alert_claim" : type;
  return await axios.delete(`/${base}s/${id}`);
};

export const submitClaim = async (
  id: Claim["id"],
  type: ClaimType
): Promise<Recall | Adjustment | Refund> => {
  if (type === ClaimType.Recall) {
    return await patchClaim(id, type, { status: ClaimStatus.Submitted });
  } else {
    return (await axios.post(`/${type}s/${id}/submit`)).data;
  }
};

export const getChannels = async (): Promise<Channel[]> => {
  return (await axios.get("/channels")).data;
};

export const getStores = async (): Promise<Store[]> => {
  return (await axios.get("/stores")).data;
};

export const patchClaim = async (
  id: Claim["id"],
  type: ClaimType,
  changes: Partial<Omit<Recall & Adjustment & Refund, "id">>
): Promise<Recall | Adjustment | Refund> => {
  return (await axios.patch(`/${type === ClaimType.Recall ? "alert_claim" : type}s/${id}`, changes))
    .data;
};

export const postComment = async (
  id: number,
  type: ClaimType,
  message: string
): Promise<Comment> => {
  if (type === ClaimType.Recall) {
    return (await axios.post(`/alert_claims/${id}/comments`, { message })).data;
  } else {
    return (await axios.post(`/${type}s/${id}/comments`, { message })).data;
  }
};

export const exportPDF = async (
  filters: { channels: Channel["id"][] | "exportAll"; claimIds: Claim["id"][] | "exportAll" },
  channelNames: string[] | "exportAll" = "exportAll"
) => {
  const channelString = Array.isArray(channelNames) ? channelNames.join(",") : channelNames;
  const filterIds = Array.isArray(filters.claimIds) ? filters.claimIds : undefined;
  const fileName = `${exportFileName()}_${channelString}.pdf`;
  const channelParam =
    filters.channels === "exportAll" ? filters.channels : filters.channels.join(",");
  axios
    .get(`/pdf/${channelParam}`, {
      responseType: "blob",
      params: { filterIds },
    })
    .then((res) => {
      download(res.data, fileName);
    })
    .catch((err) => {
      console.log(err);
    });
};

export function exportFileName() {
  let today = new Date();
  let year = today.getFullYear();
  let day = addLeadingZero(today.getDate());
  let month = addLeadingZero(today.getMonth() + 1);
  let hour = addLeadingZero(today.getHours());
  let minute = addLeadingZero(today.getMinutes());
  let seconds = addLeadingZero(today.getSeconds());
  let dateName = `${year}-${month}-${day}__${hour}.${minute}.${seconds}EST`;
  let exportFileName = `Grocer_Claims_Export__${dateName}`;
  return exportFileName;
}

export function addLeadingZero(time: Number) {
  let str = time.toString();
  if (str.length < 2) return "0" + str;
  return str;
}
