import { UppyFile } from "@uppy/core";
import { DateTime } from "luxon";
import ImageUploader from "components/atoms/ImageUploader";
import TextInput from "components/atoms/TextInput";
import SelectableItemTile from "components/organisms/SelectableItemTile";
import { SetStateAction, useEffect, useState } from "react";
import { fetchClaim, patchClaim } from "services/ClaimService";
import { deletePhoto, savePhoto } from "services/PhotoService";
import { deleteItem, getProductInfo, patchItem, postItem } from "services/ItemService";
import { Adjustment, Claim, ClaimType, Item, Recall, RecallItem, Refund } from "types";
import { Photo } from "types/photo";
import { AlertType } from "types/alert";
import { ClaimComponentProps } from ".";

export type RecallItemsProps = {
  claim: Partial<Recall & { created: any }>;
  setClaim: React.Dispatch<SetStateAction<Claim>>;
  type: "recall_pr" | "return" | "destroy";
  id: number;
} & ClaimComponentProps;

const RecallItems: React.FC<RecallItemsProps> = ({
  claim,
  setClaim,
  type,
  id,
  setCanProgress,
  setOnNext,
  viewOnly,
}) => {
  const [items, setItems] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [toUpload, setToUpload] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getClaim();
    setCanProgress(false);
    setOnNext(() => async () => {
      let { id, alert, photos, storeId, type, createDate, updateDate, ...rest } = claim;
      const validItems = items.filter((item) => item.checked && item.units >= 0);
      await Promise.all([
        patchClaim(id, ClaimType.Recall, rest),
        await Promise.all(validItems.map((item) => postItem(id, ClaimType.Recall, item))),
      ]);
    });
  }, []);

  // should add type specifications
  const checkCanProgress = ({
    newItems = null,
    newPhotos = null,
    newClaim = null,
    newToUpload = null,
    newToDelete = null,
  }) => {
    const isPriceRecall = claim.alert?.type === AlertType.PriceRecall;
    if (viewOnly) {
      setCanProgress(!isPriceRecall);
      return;
    }
    let canProgress = false;

    let updatedItems = newItems?.length > 0 ? newItems : items;
    let updatedPhotos = newPhotos ? newPhotos : photos;
    let updatedUpload = newToUpload ? newToUpload : toUpload;
    let updatedDelete = newToDelete ? newToDelete : toDelete;
    let updatedClaim = newClaim ? newClaim : claim;

    for (let i = 0; i < updatedItems.length; i++) {
      if (updatedItems[i].checked) {
        canProgress = true;
        if (!(parseInt(updatedItems[i].units) > 0)) {
          canProgress = false;
          break;
        }
      }
    }

    // if pr flow, check for completedby
    // if not pr flow:
    // return -> no further checks
    // destroy -> check for photos (required)
    canProgress =
      canProgress &&
      ((isPriceRecall && updatedClaim.completedBy.length > 0) ||
        claim.disposition === "return" ||
        updatedPhotos.length > 0);

    if (canProgress) {
      const claimID = id;
      setOnNext(() => async () => {
        const { id, alert, photos, type, storeId, createDate, updateDate, ...restOfClaim } =
          updatedClaim;
        delete restOfClaim.items;
        const results: PromiseSettledResult<Partial<Recall & RecallItem>>[] =
          await Promise.allSettled([
            await Promise.all(
              updatedUpload.map(async (photo) => {
                return await savePhoto(type, claimID, photo);
              })
            ),
            patchClaim(claimID, type, restOfClaim) as Promise<Recall>,
            await Promise.all([
              updatedDelete.map(async (photoId) => {
                return await deletePhoto(type, claimID, photoId);
              }),
            ]),
            ...(updatedItems.map(async (rawItem) => {
              const {
                itemName,
                productSize,
                imageUrl,
                imageUrlThumb,
                checked,
                claimId,
                id: itemId,
                ...item
              } = rawItem;

              if (itemId) {
                if (checked) {
                  return await patchItem(itemId, id, ClaimType.Recall, {
                    ...item,
                    itemNumber: parseInt(item.itemNumber),
                    units: parseInt(item.units),
                    cases: 0,
                  });
                } else {
                  return await deleteItem(itemId, id, ClaimType.Recall);
                }
              } else {
                return (
                  checked &&
                  item.units >= 0 &&
                  (await postItem(id, ClaimType.Recall, {
                    ...item,
                    itemNumber: parseInt(item.itemNumber),
                    units: parseInt(item.units),
                    cases: 0,
                  }))
                );
              }
            }) as Promise<RecallItem>[]),
          ] as Promise<Partial<Recall & RecallItem>>[]);
      });
    }
    setCanProgress(canProgress);
  };

  const getProducts = async (numList?, items?) => {
    let itemNumbers = numList?.length > 0 ? numList : claim.alert.itemNumbers;
    let productInfo = await getProductInfo(itemNumbers);
    let productMap = {};
    productInfo.forEach((info) => Object.assign(productMap, { [info.itemNumber]: info }));
    const newItems = itemNumbers.map((itemNumber) => ({
      itemNumber,
      ...productMap[itemNumber],
      checked: getUnits(itemNumber, items) > -1,
      units: getUnits(itemNumber, items),
      claimId: id,
      id: items?.filter((item) => item.itemNumber === itemNumber)[0]?.id,
    }));
    setItems(newItems);
    return newItems;
  };

  const getUnits = (itemNumber: number, items?: Item[]) => {
    let itemList = items ? items : claim?.items;
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].itemNumber === itemNumber) {
        return itemList[i].units > -1 ? itemList[i].units : -1;
      }
    }
    return -1;
  };

  const setClaimData = async (data) => {
    setClaim(data);
  };

  const getClaim = async () => {
    setLoading(true);
    let claimData = await fetchClaim(ClaimType.Recall, id);
    if (claimData) {
      setClaimData(claimData).then(() => {
        let claimPhotos = claimData.photos;
        const updatedPhotoState = claimPhotos
          ? claimPhotos.map((p: Photo) =>
              p.mimetype === "application/pdf" ? `pdf-${p.name} |<break>| ${p.url}` : p.url
            )
          : [];
        setPhotos(updatedPhotoState);
        getProducts(claimData.alert.itemNumbers, claimData.items).then((newItems) => {
          checkCanProgress({ newItems, newPhotos: updatedPhotoState });
          setLoading(false);
        });
      });
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const setItemData = async (newItems) => {
    setItems(newItems);
  };

  const modifyItems = (i: number, newValues: { checked?: boolean; units?: number }) => {
    let newItems = JSON.parse(JSON.stringify(items));
    newItems[i] = { ...newItems[i], ...newValues };
    setItemData(newItems).then(() => {
      checkCanProgress({ newItems });
    });
  };

  const modifyClaim = (changes: { field: string; value: any }[]) => {
    let newClaim = JSON.parse(JSON.stringify(claim));
    changes.forEach(({ field, value }) => (newClaim[field] = value));
    setClaim(newClaim);
    checkCanProgress({ newClaim });
  };

  const handleUpload = (newPhotos: UppyFile[]) => {
    const newPhotoURLs = newPhotos.map((photo) => {
      if (photo.type) {
        return photo.type === "application/pdf"
          ? `pdf-${photo.name} |<break>| ${URL.createObjectURL(photo.data)}`
          : photo.preview;
      }
      return undefined;
    });
    const updatedPhotoState = [...photos, ...newPhotoURLs];
    const newToUpload = [...toUpload, ...newPhotos];
    setPhotos(updatedPhotoState);
    setToUpload(newToUpload);
    checkCanProgress({ newPhotos: updatedPhotoState, newToUpload });
  };

  const handleDelete = (deleted) => {
    const updatedPhotoState = photos.filter((photo) => photo !== deleted);
    setPhotos(updatedPhotoState);
    const newToUpload = toUpload.filter((photo) => {
      if (deleted.substring(0, 3) === "pdf") {
        return !deleted.includes(`${photo.name}`);
      } else {
        return photo.preview !== deleted;
      }
    });
    setToUpload(newToUpload);
    let deletedPhoto = claim.photos?.filter((p) => deleted.includes(p.url));
    if (deletedPhoto?.length > 0) {
      const newToDelete = [...toDelete, ...deletedPhoto.map((p) => p.id)];
      setToDelete(newToDelete);
      checkCanProgress({ newPhotos: updatedPhotoState, newToUpload, newToDelete });
    } else {
      checkCanProgress({ newPhotos: updatedPhotoState, newToUpload });
    }
  };

  return (
    <>
      {!loading && error ? (
        <>Error - please refresh the page.</>
      ) : (
        <div>
          {type === "recall_pr" && (
            <>
              <strong>Basic Information</strong>
              <div className="info-fields">
                <div className="info-field">
                  <TextInput
                    disabled
                    label="Date Prepared"
                    value={DateTime.fromISO(
                      claim.createDate ? claim.createDate : claim.created
                    ).toLocaleString(DateTime.DATE_FULL)}
                  />
                </div>
              </div>
            </>
          )}

          <strong>Select Recalled Items</strong>
          <SelectableItemTile
            disabled={viewOnly}
            loading={loading}
            items={items}
            modifyItems={modifyItems}
          />

          <div className="info-fields">
            <div className="info-field">
              <div className="lot">
                <p>
                  <strong>Additional Comments:</strong>
                </p>
                <p>{claim.alert?.additionalInfo}</p>
              </div>
            </div>

            {type !== "recall_pr" ? (
              <>
                <div className="info-field">
                  <div className="image-copy">
                    <div className="mb-2">
                      <p>
                        <strong>ITEM PHOTOS</strong>
                        {claim.disposition === "destroy" && <div className="asterisk">*</div>}
                      </p>
                    </div>
                    {claim.disposition === "return" && (
                      <div>
                        <p>Photos may be uploaded here:</p>
                      </div>
                    )}
                    {claim.disposition === "destroy" && (
                      <div className="info-photo">
                        <p>
                          <strong>Required photos include:</strong>
                          <ul>
                            <li>All affected inventory to be destroyed; and</li>
                            <li>Close-up image of the affected lot code found on the product.</li>
                          </ul>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <ImageUploader
                  id={claim.type + " " + claim.id.toString()}
                  disableChanges={viewOnly}
                  images={photos}
                  onUpload={handleUpload}
                  onDelete={handleDelete}
                />{" "}
              </>
            ) : (
              <div className="info-field">
                <TextInput
                  value={claim.completedBy}
                  label="Claim completed by (Full Name)"
                  placeholder="Your Full Name"
                  onChange={(e) => {
                    modifyClaim([{ field: "completedBy", value: e.target.value }]);
                  }}
                  disabled={viewOnly}
                  asterisk
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RecallItems;
