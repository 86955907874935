import CheckIcon from "components/atoms/Icons/CheckIcon";
import ExclamationIcon from "components/atoms/Icons/ExclamationIcon";
import React, { useState, useEffect } from "react";
import TextInput, { TextInputProps } from "../atoms/TextInput";

export type ValidatedInputProps = TextInputProps &
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    hideOnPass?: boolean;
    onCheck?: (allPass: boolean) => void;
    validations?: { condition: (value: string) => boolean; label: string }[];
    triggerCheck?: any[];
  };

const ValidatedInput: React.FC<ValidatedInputProps> = ({
  validations = [],
  onChange = (e) => {},
  sizing = "md",
  defaultValue = "",
  hideOnPass = false,
  onCheck = (e) => {},
  triggerCheck = [], // used purely to trigger re-render and run checks
  ...rest
}) => {
  const [passes, setPasses] = useState([]);

  useEffect(() => {
    if (rest.value) {
      runChecks(rest.value);
    } else {
      runChecks(defaultValue);
    }
  }, triggerCheck);

  const runChecks = (value: string) => {
    if (validations.length > 0) {
      let successes = validations.map(({ condition }, i) => (condition(value) ? i : -1));
      setPasses(successes);
      if (successes.includes(-1)) {
        onCheck(false);
      } else {
        onCheck(true);
      }
    }
  };

  const handleChange = (e) => {
    onChange(e);
    runChecks(e.target.value);
  };

  return (
    <div className={"validated-input" + " " + sizing}>
      <TextInput onChange={handleChange} defaultValue={defaultValue} {...rest} />
      <div className={"list" + (validations.length > 0 ? "" : "none")}>
        {validations.map(({ label }, i) => (
          <div
            className={
              "validation " + (passes.includes(i) ? (hideOnPass ? "none" : "pass") : "fail")
            }
            key={i}
          >
            {passes.includes(i) ? <CheckIcon height="100%" /> : <ExclamationIcon height="100%" />}
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ValidatedInput;
