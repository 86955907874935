import Checkbox from "components/atoms/Checkbox";
import TextInput from "components/atoms/TextInput";
import { SetStateAction, useEffect, useState } from "react";
import { fetchClaim, patchClaim } from "services/ClaimService";
import { Claim, ClaimType, Recall } from "types";
import { ClaimComponentProps } from ".";

export type RecallSubmitProps = {
  claim: Partial<Recall & { created: any }>;
  setClaim: React.Dispatch<SetStateAction<Claim>>;
  type: "return" | "destroy";
  id: number;
} & ClaimComponentProps;

const RecallSubmit: React.FC<RecallSubmitProps> = ({
  claim,
  setClaim,
  type,
  id,
  setCanProgress,
  setOnNext,
  viewOnly,
}) => {
  const BASE_AGREEMENTS = {
    recall_pr: [],
    return: [
      {
        content: (
          <p>
            By checking this box you agree that the product(s) and quantities reported above are
            correct and accurately reflect the recalled product and affected Lot.
          </p>
        ),
      },
    ],
    destroy: [
      {
        content: (
          <p>
            By checking this box you agree that the product(s) and quantities reported above are
            correct and accurately reflect the recalled product and affected Lot(s) in this Authorized Store location.
          </p>
        ),
      },
      {
        content: (
          <div className="destroy-content">
            <p>By checking this box you agree to the attestation below:</p>
            <p>
              I confirm on behalf of the vendor organization listed, that I have confirmed the
              product is as described in the QA Alert, and that the product(s) has/have been
              secured for destruction, pending LCBO approval.
            </p>
            <p>
              I confirm on behalf of the vendor organization listed that, <strong> once approved by LCBO, </strong>
              the submitted quantity of product(s) will be destroyed in accordance with the LCBO’s
              Policies & Procedures Manual for Authorized Store Operators.
            </p>
            <div className="do-not-destroy">
              <p>
                <strong>
                  Do NOT destroy any product until this Claim request is approved by the LCBO - Product
                  destroyed without approval may not be credited.
                </strong>
              </p>
            </div>
          </div>
        ),
      },
    ],
  };

  const [agrees, setAgrees] = useState(
    // should be BASE_AGREEMENTS[type].map, but type isnt updating properly in viewOnly
    BASE_AGREEMENTS[claim.disposition].map((agree) => ({ ...agree, checked: false }))
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getClaim();
    setCanProgress(false);
    setOnNext(() => async () => {
      let { id, alert, photos, storeId, type, createDate, updateDate, ...rest } = claim;
      await patchClaim(id, ClaimType.Recall, rest);
    });
  }, []);

  const checkCanProgress = ({ newAgrees = null, newClaim = null }) => {
    if (viewOnly) {
      setCanProgress(false);
      return;
    }
    let canProgress = true;
    let checkAgrees = newAgrees?.length > 0 ? newAgrees : agrees;
    let checkClaim = newClaim ? newClaim : claim;

    checkAgrees.forEach((agree) => {
      if (agree.checked !== true) {
        canProgress = false;
      }
    });

    canProgress =
      canProgress &&
      checkClaim.completedByTitle?.length > 0 &&
      checkClaim.completedBy?.length > 0 &&
      (checkClaim.storePhoneNumber?.length > 9 || claim.disposition === "destroy");

    if (canProgress) {
      setOnNext(() => async () => {
        let { id, alert, photos, storeId, type, createDate, updateDate, ...rest } = checkClaim;
        await patchClaim(id, ClaimType.Recall, rest);
      });
    }
    setCanProgress(canProgress);
  };

  const setClaimData = async (data) => {
    setClaim(data);
  };

  const getClaim = async () => {
    setLoading(true);
    let claimData = await fetchClaim(ClaimType.Recall, id);
    if (claimData) {
      setClaimData(claimData).then(() => {
        checkCanProgress({});
        setLoading(false);
      });
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const modifyClaim = (changes: { field: string; value: any }[]) => {
    let newClaim = JSON.parse(JSON.stringify(claim));
    changes.forEach(({ field, value }) => (newClaim[field] = value));
    setClaim(newClaim);
    checkCanProgress({ newClaim });
  };

  const modifyAgreement = async (i) => {
    let newAgrees = agrees;
    newAgrees[i].checked = !(agrees[i].checked === true);
    setAgrees(newAgrees);
    checkCanProgress({ newAgrees });
  };

  const phoneFormat = (input) => {
    if (!input) return "";
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, "");

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    const size = input.length;
    if (size === 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ")-" + input.substring(3, 6);
    } else {
      input =
        "(" + input.substring(0, 3) + ")-" + input.substring(3, 6) + "-" + input.substring(6, 10);
    }
    return input;
  };

  return (
    <>
      {!loading && error ? (
        <>Error - please refresh the page.</>
      ) : (
        <div>
          <strong>Confirmation</strong>
          <div className="info-fields">
            <div className="info-field">
              <TextInput
                defaultValue={claim.completedBy}
                label="Submitted on Behalf of Authorized Grocer (Full Name)"
                placeholder="Your Full Name"
                onChange={(e) => {
                  modifyClaim([{ field: "completedBy", value: e.target.value }]);
                }}
                disabled={viewOnly}
                asterisk
              />
            </div>
            <div className="info-field">
              <TextInput
                defaultValue={claim.completedByTitle}
                label="Title"
                placeholder="Your Title"
                onChange={(e) => {
                  modifyClaim([{ field: "completedByTitle", value: e.target.value }]);
                }}
                disabled={viewOnly}
                asterisk
              />
            </div>
            {/* should be type === "return", but type isnt updating properly in viewOnly  */}
            {claim.disposition === "return" && (
              <div className="info-field">
                <TextInput
                  defaultValue={claim.storePhoneNumber}
                  value={phoneFormat(claim.storePhoneNumber) || ""}
                  label="Store Phone Number"
                  placeholder="e.g. (123)-456-7890"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      modifyClaim([
                        {
                          field: "storePhoneNumber",
                          value: e.target.value.replace(/\D/g, "").substring(0, 10),
                        },
                      ]);
                    }
                  }}
                  type="tel"
                  disabled={viewOnly}
                  asterisk
                />
              </div>
            )}
          </div>
          <hr />
          {agrees.map((agreement, i) => {
            return (
              <div className="agreement" key={i}>
                <Checkbox
                  defaultValue={agreement.checked || viewOnly}
                  onChange={() => {
                    modifyAgreement(i);
                  }}
                  disabled={viewOnly}
                />
                <div className="content">
                  {agreement.content}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default RecallSubmit;
