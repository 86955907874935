import { DateTime } from "luxon";

enum AlertType {
  PriceRecall = "price_recall",
  QAAlert = "qa_alert",
}

interface Alert {
  id: string;
  alertNumber: number;
  type: AlertType;
  expiryDate: DateTime;
  active: boolean;
  itemNumbers: number[];
  lotCodes: string[];
  updated: DateTime;
  created: DateTime;
  additionalInfo: string;
  isArchived: boolean;
  disposition: "both" | "return" | "destroy";
}

export { AlertType };
export type { Alert };
