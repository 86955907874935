import { Store } from "./store";
import { Channel } from "./channel";

enum UserType {
  Admin = "admin",
  LCBO = "lcbo",
  Grocery = "grocery",
  LCBOReporter = "lcboReporter",
  GrocerReporter = "grocerReporter",
  Inactive = "inactive",
  Disabled = "disabled",
  QAAdmin = "qaAdmin",
}

interface User {
  id: number;
  createDate: Date;
  updateDate: Date;
  email: string;
  contactName: string;
  type: UserType;
  store: Store;
}

interface Reporter extends User {
  channels: Channel[];
}

export type { User, Reporter };
export { UserType };
