import React, { useEffect, useState } from "react";
import ClaimBrowser from "components/organisms/ClaimBrowser";
import { useHistory } from "react-router-dom";
import { createClaim, fetchClaims } from "../services/ClaimService";
import RefundIcon from "../components/atoms/Icons/RefundIcon";
import AdjustmentIcon from "../components/atoms/Icons/AdjustmentIcon";
import RecallIcon from "../components/atoms/Icons/RecallIcon";
import { Claim, ClaimType, UserType } from "types";
import { Header } from "components/organisms/Header";
import { ClaimTypeTile } from "components/atoms/ClaimTypeTile";
import { UserService } from "services/UserService";

const claimTypes = [
  {
    text: "Return or Refund",
    icon: <RefundIcon />,
  },
  {
    text: "Damage/ Shortage/ Overage",
    icon: <AdjustmentIcon />,
  },
  {
    text: "Product Recall",
    icon: <RecallIcon />,
  },
];

const CLAIM_TYPES = [ClaimType.Refund, ClaimType.Adjustment, ClaimType.Recall];

const Home: React.FC = () => {
  const [claims, setClaims] = useState<Claim[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchUserClaims = async () => {
    setLoading(true);
    fetchClaims()
      .then((res) => {
        setClaims(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (UserService.getType() === UserType.GrocerReporter) history.push("/reporter");
    fetchUserClaims();
  }, []);

  const handleClaimClick = async (type: ClaimType) => {
    return createClaim(type).then((res) => {
      history.push(`/claim/${type}/${res.id}`);
    });
  };

  const history = useHistory();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header variant="home" />

      {claims && (
        <div className="home">
          {/* <div className="comments-modal-container">
            <Modal
              handleClose={() => {
                setShowModal(false);
              }}
              show={showModal}
            >
              <CommentsModal
                loading={loading}
                claim={activeClaim}
                products={activeProducts}
                onComment={fetchActiveClaim}
                onBack={() => {
                  setShowModal(false);
                }}
                history={history}
              />
            </Modal>
          </div> */}
          <div className="upper">
            <div className="cta">
              <div>Create a new claim</div>
            </div>
            <div className="claim-types">
              {claimTypes.map((type, i) => (
                <ClaimTypeTile
                  text={type.text}
                  icon={type.icon}
                  handleClick={() => handleClaimClick(CLAIM_TYPES[i])}
                />
              ))}
            </div>
          </div>
          <ClaimBrowser
            claims={claims}
            onSelect={(claim) => history.push(`/status/${claim.type}/${claim.id}`)}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
