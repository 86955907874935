import axios from "./BaseService";
import Uppy, { UppyFile } from "@uppy/core";
import ThumbnailGenerator from "@uppy/thumbnail-generator";
import { ClaimType } from "types/claim";

const createUppy = () => {
  return new Uppy({
    restrictions: {
      minNumberOfFiles: 1,
      allowedFileTypes: ["image/png", "image/jpeg", "application/pdf"],
    },
  })
    .use(ThumbnailGenerator, {
      id: "ThumbnailGenerator",
      thumbnailWidth: 200,
      thumbnailHeight: 200,
      thumbnailType: "image/jpeg",
      waitForThumbnailsBeforeUpload: false,
    })
};

const generateURL = (
  claimType: ClaimType,
  id: number,
  itemId?: number,
  photoId?: number
): string => {
  let endpoint = "/";
  if (claimType === ClaimType.Refund || claimType === ClaimType.Adjustment) {
    endpoint += `${claimType}s/${id}`;
  } else {
    endpoint += `alert_claims/${id}`;
  }
  endpoint += itemId ? `/items/${itemId}/photos` : "/photos";
  endpoint += photoId ? `/${photoId}` : "";
  return endpoint;
};

const deletePhoto = async (claimType: ClaimType, id: number, photoId: number, itemId?: number) => {
  const endpoint = generateURL(claimType, id, itemId, photoId);
  await axios.delete(endpoint);
};

const savePhoto = async (claimType: ClaimType, id: number, file: UppyFile, itemId?: number) => {
  const endpoint = generateURL(claimType, id, itemId);
  const data = new FormData();
  data.append("photo", file.data);
  const res = await axios.post(endpoint, data);
  return res;
};

const getPhoto = async (claimType: ClaimType, id: number, photoId: number, itemId?: number) => {
  const endpoint = generateURL(claimType, id, itemId, photoId);
  return await axios.get(endpoint);
};

export { createUppy, deletePhoto, savePhoto, getPhoto };
