import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import adjustment from "./adjustment.md";
import refund from "./refund.md";
import recall_pr from "./recall_pr.md";
import recall_qa from "./recall_qa.md";
import { ClaimComponentProps } from "..";

export type InstructionsProps = {
  type: "adjustment" | "refund" | "recall_pr" | "recall_qa";
  setStay: React.Dispatch<boolean>;
} & ClaimComponentProps;

const Instructions: React.FC<InstructionsProps> = ({
  type,
  setCanProgress,
  setOnNext,
  setStay,
}) => {
  const [text, setText] = useState("");
  const typeToImportMap = {
    adjustment: adjustment,
    refund: refund,
    recall_pr: recall_pr,
    recall_qa: recall_qa,
  };
  useEffect(() => {
    setOnNext(() => async () => {});
    fetch(typeToImportMap[type])
      .then((res) => {
        return res.text();
      })
      .then((txt) => {
        setText(txt);
        setStay(false);
        setCanProgress(true);
      });
  }, []);

  return <ReactMarkdown>{text}</ReactMarkdown>;
};

export default Instructions;
