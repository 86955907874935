import React, { useState } from "react";
import DropdownArrowIcon from "./Icons/DropdownArrowIcon";

export type DropdownProps = {
  options: { label: string; value: any }[];
  onSelect: (value: any) => void;
  placeholder?: string;
  defaultValue?: string;
  label?: string;
  disabled?: boolean;
  asterisk?: boolean;
  value?: any;
};

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  placeholder = "Select an item...",
  defaultValue = "",
  label = "",
  disabled = false,
  asterisk = false,
  value = null,
}) => {
  const [val, setVal] = useState<string>(value ? value : defaultValue);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      {label.length > 0 && (
        <div className="label">
          {label}
          <div className="asterisk">{asterisk ? "*" : ""}</div>
        </div>
      )}
      <div className="dropdown">
        <button
          type="button"
          className={`dropdown-header ${(!value && !val) ? "dropdown-placeholder" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
        >
          {value
            ? options.filter((opt) => opt.value === value)[0]?.label
            : val
            ? options.filter((opt) => opt.value === val)[0]?.label
            : placeholder}
          <DropdownArrowIcon up={isOpen} />
        </button>
        {isOpen && !disabled && (
          <div className="dropdown-list">
            {options.map((item) => (
              <button
                type="button"
                key={item.value}
                className="dropdown-list-item"
                onClick={() => {
                  setVal(item.value);
                  onSelect(item.value);
                  setIsOpen(!isOpen);
                }}
              >
                {item.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown;
