import * as React from "react";

function SvgAdjustmentIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color ? props.color : "white";
  return (
    <svg
      width="60"
      height="48"
      viewBox="0 0 60 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5981 47.3615C11.8227 47.3615 8.75098 46.1257 8.75098 44.6062V35.5686L11.618 33.0959L14.267 36.7098L17.881 31.5742L22.446 34.4273V44.6062C22.446 46.1257 19.3743 47.3615 15.5981 47.3615Z"
        fill={color}
      />
      <path
        d="M12.2536 12.5008C12.4456 13.2174 13.0562 13.6971 13.9732 13.8522C15.9197 14.1826 17.3241 15.2833 17.7289 16.7966L21.4989 30.8665L16.351 29.2921L14.1894 35.1881L10.6953 32.3829L8.56593 35.5134L4.50053 20.3411C4.0951 18.8281 4.76114 17.1731 6.28235 15.9135C6.99979 15.321 7.28857 14.5998 7.09655 13.8832L4.61556 4.62395L4.16857 4.74372L3.81643 3.42951L4.7219 2.27969L8.50713 1.26544L9.86619 1.80848L10.2182 3.12218L9.77259 3.24157L12.2536 12.5008Z"
        fill={color}
      />
      <path
        d="M52.9182 13.8754C52.727 14.5923 53.0168 15.3126 53.7342 15.9046C55.2562 17.1622 55.9241 18.8169 55.5198 20.3302L53.5309 27.7884L50.272 28.8879L48.9118 25.1786L46.4858 26.894L44.4343 24.9689L41.2173 27.4576L40.2984 24.2597L42.2872 16.8016C42.6908 15.288 44.0937 14.1861 46.0406 13.8536C46.958 13.698 47.5681 13.2171 47.7592 12.5003L50.2291 3.2381L49.782 3.11887L50.1326 1.80424L51.491 1.25957L55.2774 2.26928L56.1843 3.41801L55.8338 4.73213L55.3881 4.61326L52.9182 13.8754Z"
        fill={color}
      />
      <path
        d="M46.7905 47.5526C43.0151 47.5526 39.9434 46.3168 39.9434 44.7973V27.7709L41.6552 30.624L44.1224 27.3905L46.6006 28.722L48.5027 26.4395L50.7727 29.673L53.6383 27.7709V44.7973C53.6383 46.3168 50.5667 47.5526 46.7905 47.5526Z"
        fill={color}
      />
      <path
        d="M33.8648 12.9638C33.8648 13.7056 34.3304 14.327 35.176 14.7142C36.9707 15.5372 38.0424 16.9639 38.0417 18.5303V26.2491L35.176 28.1512L32.906 24.9176L31.004 27.2001L28.5257 25.8687L26.0586 29.1022L24.3467 26.2491V18.5303C24.3467 16.9639 25.4183 15.5377 27.2137 14.7147C28.0601 14.3281 28.5257 13.7062 28.5257 12.9643V3.37846H28.0629V2.01789L29.2351 1.1416H33.1539L34.3261 2.01789V3.37792H33.8648V12.9638Z"
        fill={color}
      />
      <path
        d="M31.1938 47.5526C27.4184 47.5526 24.3467 46.3168 24.3467 44.7973V27.7709L26.0586 30.624L28.5257 27.3905L31.004 28.722L32.906 26.4395L35.176 29.673L38.0417 27.7709V44.7973C38.0417 46.3168 34.97 47.5526 31.1938 47.5526Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgAdjustmentIcon;
