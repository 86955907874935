import React, { useState } from "react";
import SearchIcon from "./Icons/SearchIcon";
import ClearInputIcon from "./Icons/ClearInputIcon";

export type SearchProps = {
  onChange: (value: any) => void;
  placeholder?: string;
  clearable?: boolean;
};

const Search: React.FC<SearchProps> = ({
  onChange,
  placeholder = "Search by claim number, status, type...",
  clearable = true,
}) => {
  const [value, setValue] = useState<string>("");

  const handleUserInput = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };
  return (
    <div className="searchbar">
      <SearchIcon className="search" />
      <input type="text" placeholder={placeholder} value={value} onChange={handleUserInput} />
      {clearable && (
        <ClearInputIcon
          onClick={() => {
            setValue("");
            onChange("");
          }}
        />
      )}
    </div>
  );
};

export default Search;
