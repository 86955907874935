import axios from "axios";
import { env } from "../env"; 

let baseURL = env.base_url;

const instance = axios.create({
  baseURL: baseURL,
});

export default instance;
