import AdjustmentIcon from "components/atoms/Icons/AdjustmentIcon";
import CloseIcon from "components/atoms/Icons/CloseIcon";
import RecallIcon from "components/atoms/Icons/RecallIcon";
import RefundIcon from "components/atoms/Icons/RefundIcon";
import { DateTime } from "luxon";
import React from "react";
import { Claim } from "types";

export type ClaimInfoModalProps = Pick<
  Claim,
  "type" | "id" | "items" | "createDate" | "updateDate" | "status"
> & {
  onClose: () => void;
};

const ClaimInfoModal: React.FC<ClaimInfoModalProps> = ({
  id,
  type,
  items,
  createDate,
  updateDate,
  status,
  onClose,
}) => {
  const claimInfoMap = {
    Status: status,
    "Date Created": createDate.toLocaleString(DateTime.DATE_HUGE),
    "Date Last Updated": updateDate.toLocaleString(DateTime.DATE_HUGE),
  };

  const itemInfoMap = {
    "Item Name": "itemName",
    "Item Description": "productDescription",
    "Item Number": "itemNumber",
    "Receival Date": "receivalDate",
    "Purchase Number": "purchaseNumber",
  };

  const typeToContentMap = {
    adjustment: {
      label: "Adjustment",
      icon: <AdjustmentIcon color="#2a55a2" />,
    },
    recall: {
      label: "Recall",
      icon: <RecallIcon color="#2a55a2" />,
    },
    refund: {
      label: "Refund",
      icon: <RefundIcon color="#2a55a2" />,
    },
  };
  return (
    <div className="claim-info-modal">
      <div className="modal-header">
        <div className="close-row">
          <div className="icon">
            <CloseIcon onClick={onClose} />
          </div>
        </div>
        {typeToContentMap[type].icon}
        <div className="modal-title">
          {typeToContentMap[type].label} #{id}
        </div>
      </div>
      <div className="claim-info">
        {Object.keys(claimInfoMap).map((key) => (
          <div className="info-pair">
            <div className="key">{key}</div>
            <div className={"value " + claimInfoMap[key]}>{claimInfoMap[key]}</div>
          </div>
        ))}
        {items
          .map(({ itemName, productDescription, itemNumber, receivalDate, purchaseNumber }) => ({
            itemName,
            productDescription,
            itemNumber,
            receivalDate: receivalDate?.toLocaleString(DateTime.DATE_HUGE),
            purchaseNumber,
          }))
          .flatMap((item, i) =>
            Object.keys(itemInfoMap).map((key) => {
              if (key === "Item Number" && item[itemInfoMap[key]] < 0) {
                item[itemInfoMap[key]] = "Custom";
              }
              return item[itemInfoMap[key]] ? (
                <div className="info-pair">
                  <div className="key">
                    {key} (Item {i + 1})
                  </div>
                  <div className={"value"}>{item[itemInfoMap[key]]}</div>
                </div>
              ) : (
                ""
              );
            })
          )}
      </div>
      <br />
    </div>
  );
};

export default ClaimInfoModal;
