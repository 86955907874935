import { Dispatch, useState, useEffect, SetStateAction } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { UserService } from "../services/UserService";
import { User } from "types";
import LOGO from "../components/atoms/assets/lcbo_logo.png";
import ValidatedInput from "components/organisms/ValidatedInput";
import { Button } from "components/atoms/Button";
import { Row } from "react-bootstrap";
import ExclamationIcon from "components/atoms/Icons/ExclamationIcon";

export type LoginProps = {
  signedIn: boolean;
  setSignedIn: Dispatch<SetStateAction<Boolean>>;
  reset?: boolean;
};

const Login: React.FC<LoginProps> = ({ signedIn, setSignedIn, reset = false }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [knowsPass, setKnowsPass] = useState(true);
  const [triggerCheck, setTriggerCheck] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    if (reset) {
      setLoading(false);
    } else {
      redirectIfSignedIn();
    }
  }, []);

  const redirectIfSignedIn = async () => {
    UserService.tryAuth()
      .then((res) => {
        setSignedIn(res);
      })
      .finally(() => setLoading(false));
  };

  const handleButtonPress = async () => {
    setLoading(true);
    if (reset) {
      if (password === confirm && /\d/.test(password) && password.length >= 6) {
        const creds = {
          password,
          token: history.location.pathname?.split("/")?.pop(),
        };
        UserService.updatePassword(creds)
          .then((success) => {
            if (success) {
              setInvalidLogin(false);
              history.push("/login");
            } else {
              setInvalidLogin(true);
            }
          })
          .catch((err) => {
            setInvalidLogin(true);
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setInvalidLogin(true);
        setLoading(false);
      }
    } else if (knowsPass) {
      UserService.login({ email, password })
        .then((data: User) => {
          setSignedIn(true);
          setInvalidLogin(false);
        })
        .catch((err) => {
          setInvalidLogin(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      UserService.reset({ email })
        .then(() => {
          console.log("implement messaging for successful reset email sent");
        })
        .catch(() => {
          console.log("implement messaging for unsuccessful reset email sent");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleFieldChange = (value: string, i: number) => {
    const functions = [setEmail, setPassword, setConfirm];
    functions[reset ? i + 1 : i](value);
  };

  return (
    <>
      {signedIn ? (
        <Redirect to="/" />
      ) : (
        <>
          {!loading && (
            <div className="d-flex flex-column min-vh-100">
              <div className="login">
                <div className="img-container">
                  <img src={LOGO} />
                </div>

                <h1>Welcome to</h1>
                <h2>LCBO B2B Claims Application</h2>

                <Row>
                  <div className={`col-xs-12 col-md-${knowsPass ? "6" : "12"} text-input`}>
                    <ValidatedInput
                      type={!reset ? "text" : "password"}
                      label={reset ? "Password" : "Email"}
                      onChange={(e) => {
                        handleFieldChange(e.target.value, 0);
                        setTriggerCheck(!triggerCheck);
                      }}
                      hideOnPass
                      validations={
                        reset
                          ? [
                              {
                                condition: (value: string) => `${value}`.length >= 6,
                                label: "Must be at least 6 characters.",
                              },
                              {
                                condition: (value: string) => /\d/.test(value),
                                label: "Must contain at least 1 number.",
                              },
                            ]
                          : []
                      }
                    />
                  </div>
                  {knowsPass && (
                    <div className="col-xs-12 col-md-6 text-input">
                      <ValidatedInput
                        label={`${reset ? "Confirm " : ""} Password`}
                        type="password"
                        onChange={(e) => {
                          handleFieldChange(e.target.value, 1);
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleButtonPress();
                          }
                        }}
                        hideOnPass
                        validations={
                          reset
                            ? [
                                {
                                  condition: (value: string) =>
                                    value === password && password.length > 0,
                                  label: "Both passwords must match.",
                                },
                              ]
                            : []
                        }
                        triggerCheck={[triggerCheck]}
                      />
                    </div>
                  )}
                </Row>
                {!reset && (
                  <div
                    onClick={() => {
                      setKnowsPass(!knowsPass);
                      setInvalidLogin(false);
                    }}
                    className="reset"
                  >
                    {knowsPass ? "Forgot your password?" : "Back to login"}
                  </div>
                )}
                <Button styling="login-btn" onClick={handleButtonPress}>
                  {reset ? "Submit" : knowsPass ? "Login" : "Reset"}
                </Button>
                {invalidLogin ? (
                  <div className="invalid">
                    <ExclamationIcon height="80%" />
                    {reset ? "Password update failed." : "Invalid Email/Password."}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Login;
