function SvgCommentNotifIcon() {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V1.913C0.0224326 1.39779 0.243009 0.911159 0.615678 0.554701C0.988347 0.198243 1.4843 -0.000487239 2 8.9712e-07H18C18.5304 8.9712e-07 19.0391 0.210714 19.4142 0.585787C19.7893 0.96086 20 1.46957 20 2V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16ZM2 3.868V14H18V3.868L10 9.2L2 3.868ZM2.8 2L10 6.8L17.2 2H2.8Z"
        fill="#EB5757"
      />
    </svg>
  );
}

export default SvgCommentNotifIcon;
