import React from "react";

export type ClaimTypeTileProps = {
  icon: React.ReactNode;
  text: string;
  handleClick: (e) => void;
};

export const ClaimTypeTile: React.FC<ClaimTypeTileProps> = (props) => {
  return (
    <div onClick={props.handleClick} className="claim-type-tile">
      <div className="icon">{props.icon}</div>
      <div className="text">{props.text}</div>
    </div>
  );
};
