import Dropdown from "components/atoms/Dropdown";
import ItemCarousel from "components/organisms/ItemCarousel";
import React, { useEffect, useState } from "react";
import { getAlerts } from "services/AlertService";
import { patchClaim } from "services/ClaimService";
import { AlertType } from "types/alert";
import { ClaimType } from "types/claim";
import { ClaimComponentProps } from ".";

export type RecallSwitcherProps = {
  claimId: string;
  setType: React.Dispatch<any>;
  setStay: React.Dispatch<boolean>;
} & ClaimComponentProps;

const RecallSwitcher: React.FC<RecallSwitcherProps> = ({
  claimId,
  setType,
  setStay,
  setOnNext,
  setCanProgress,
}) => {
  const [alerts, setAlerts] = useState([]);
  const [id, setId] = useState("");
  const [chosen, setChosen] = useState(null);
  useEffect(() => {
    setStay(true);
    setCanProgress(false);
    fetchAlerts();
  }, []);
  const fetchAlerts = async () => {
    setAlerts(await getAlerts());
  };
  return (
    <div>
      <div>
        Please select assigned alert number that includes your product(s). Be sure to double check
        that this is the exact product in your inventory.
      </div>
      <br />
      <Dropdown
        options={alerts.map((alert) => ({
          value: alert.id,
          label:
            alert.alertNumber +
            " – " +
            alert.itemInfo
              .map((item) => `#${item.itemNumber} ${item.itemName} (${item.productSize})`)
              .join(" ; "),
        }))}
        onSelect={(value) => {
          setId(value);
          for (let i = 0; i < alerts.length; i++) {
            if (alerts[i].id === value) {
              setChosen(alerts[i]);
              setOnNext(() => async () => {
                setType(alerts[i].type === AlertType.PriceRecall ? "recall_pr" : "recall_qa");
                patchClaim(parseInt(claimId), ClaimType.Recall, { alertId: value });
              });
              setCanProgress(true);

              break;
            }
          }
        }}
      />
      {id.length > 0 && (
        <>
          <br />
          <ItemCarousel items={chosen.itemInfo} />
          <br />
          <div className="undergo">
            This product {chosen.type === "price_recall" ? "will undergo a" : "is the subject of a"}{" "}
            <strong>
              {chosen.type === "price_recall" ? "Wine Floor Price Product" : "QA Alert Product"} Recall.
            </strong>
          </div>
          <br />
        </>
      )}
    </div>
  );
};

export default RecallSwitcher;
