import * as React from 'react';

function SvgCarouselArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="34"
      viewBox="0 0 19 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.2955 0.833984C15.7146 0.833984 15.2498 1.00827 14.9012 1.35684L0.696947 15.474C0.290281 15.9387 0.0869484 16.4326 0.0869484 16.9554C0.0869484 17.4783 0.290281 17.943 0.696947 18.3497L14.9012 32.4668C15.3079 32.8735 15.7727 33.0768 16.2955 33.0768C16.8184 33.0768 17.2831 32.8735 17.6898 32.4668C18.0965 32.0602 18.2998 31.5954 18.2998 31.0726C18.2998 30.5497 18.0965 30.0849 17.6898 29.6783L4.96695 16.9554L17.6898 4.23256C18.0965 3.82589 18.2998 3.33208 18.2998 2.75113C18.2998 2.17017 18.111 1.70541 17.7334 1.35684C17.3558 1.00827 16.8765 0.833984 16.2955 0.833984Z"
        fill="#959595"
      />
    </svg>
  );
}

export default SvgCarouselArrowIcon;
