import React from "react";

export type StickyFooterProps = { children: React.ReactNode; escaped?: boolean };

const StickyFooter: React.FC<StickyFooterProps> = ({ children, escaped = false }) => {
  return (
    <div className="sticky-footer">
      {escaped ? children : <div className="footer-children">{children}</div>}
    </div>
  );
};

export default StickyFooter;
