import ItemCard from "components/atoms/ItemCard";
import { Item } from "types";
import { Button } from "../atoms/Button";

export type ItemManagerProps = {
  items: Item[];
  missing: number[];
  onAdd: () => void;
  onEdit: (item: Item, i: number) => void;
  onDelete: (item: Item, i: number) => void;
  viewOnly?: boolean;
};

const ItemManager: React.FC<ItemManagerProps> = ({
  items,
  onDelete,
  onEdit,
  onAdd,
  missing,
  viewOnly = false,
}) => {
  return (
    <div className="item-manager">
      <div className="row">
        {items
          .sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime())
          .map((item, i) => {
            return (
              <div className="col-md-6">
                <ItemCard
                  index={i + 1}
                  productDescription={item.productDescription}
                  itemNumber={item.itemNumber}
                  missing={missing.includes(i)}
                  onDelete={() => onDelete(item, i)}
                  onEdit={() => onEdit(item, i)}
                  viewOnly={viewOnly}
                />
              </div>
            );
          })}
        <br />
        {!viewOnly && (
          <div className="add-item">
            <Button styling="next-btn" onClick={onAdd}>
              Add Item
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemManager;
