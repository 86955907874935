import { useState } from "react";
import CarouselArrowIcon from "components/atoms/Icons/CarouselArrowIcon";
import { Product } from "types";
import ItemCarouselCard from "components/atoms/ItemCarouselCard";

export type ClaimProps = {
  items: Pick<Product, "itemNumber" | "itemName" | "imageUrlThumb" | "productSize">[];
};

const Claim: React.FC<ClaimProps> = ({ items }) => {
  const [idx, setIdx] = useState(0);
  return (
    <div className="item-carousel">
      <div className="arrow left">
        {idx !== 0 && <CarouselArrowIcon onClick={() => setIdx(idx - 1)} />}
      </div>
      <ItemCarouselCard {...items[idx]} index={idx + 1} outOf={items.length} />
      <div className="arrow right">
        {idx !== items.length - 1 && <CarouselArrowIcon onClick={() => setIdx(idx + 1)} />}
      </div>
    </div>
  );
};

export default Claim;
