import { AxiosPromise, AxiosRequestConfig } from "axios";
import axios from "./BaseService";
import Toast from "../components/atoms/Toast";
import { PasswordRequirements, Reporter, User, UserType } from "types";

let token: string | null = localStorage.getItem("token");

export const setToken = (newToken: string | null) => {
  token = newToken;
  token ? localStorage.setItem("token", token) : localStorage.removeItem("token");
};

function addAuthentication(config: AxiosRequestConfig) {
  if (token && (config.url!!.indexOf(config.baseURL!!) === 0 || config.url!![0] === "/")) {
    // request to server!
    config.headers.common.Authorization = "Bearer " + token;
  }
  return config;
}

axios.interceptors.request.use(addAuthentication);

function handleErrors(error: any) {
  if (error.response && (error.response.status === 400 || error.response.status === 401))
    return Promise.reject(error);
  if (error.response && error.response.data && error.response.data.message)
    Toast.throw({ message: "Request failed: " + error.response.data.message });
  else if (error.message) Toast.throw({ message: "Request failed: " + error.message });
  else Toast.throw({ message: "Request failed" });
  return { then: function () {} };
}

axios.interceptors.response.use(undefined, handleErrors);

export class UserService {
  private static userId: number | null = null;
  private static userType: UserType | null = null;

  static getType = () => {
    return UserService.userType;
  };

  static getId = () => {
    return UserService.userId;
  };

  static isValidType = () => {
    return (
      UserService.userType === UserType.GrocerReporter || UserService.userType === UserType.Grocery
    );
  };

  static fetchUser = (id: number = UserService.userId!!): Promise<User | Reporter> => {
    return new Promise((resolve, reject) => {
      axios.get("/users/" + id).then(({ data }) => {
        resolve(data);
      }, reject);
    });
  };

  static login = (credentials: object) => {
    return new Promise((resolve, reject) => {
      axios.post("/login", credentials).then(({ data }) => {
        setToken(data.token);
        UserService.userId = data.id;
        UserService.userType = data.type;
        UserService.fetchUser(data.id).then((userData) => {
          if (!UserService.isValidType()) {
            UserService.logout().then(() => {
              console.log("TODO: Messaging on invalid account type");
              reject("TODO: Messaging on invalid account type");
            });
          } else {
            resolve(userData);
          }
        }, reject);
      }, reject);
    });
  };

  static tryAuth = (): Promise<boolean> => {
    if (UserService.userId !== null) return Promise.resolve(true);
    if (token === null) return Promise.resolve(false);
    return new Promise((resolve, reject) => {
      axios.get("/authorized").then(
        ({ data }) => {
          UserService.userId = data.id;
          UserService.userType = data.type;
          UserService.fetchUser().then(() => resolve(true), reject);
        },
        () => {
          resolve(false);
        }
      );
    });
  };

  static logout = () => {
    return new Promise((resolve, reject) => {
      axios.post("/logout").then((success) => {
        setToken(null);
        UserService.userId = null;
        UserService.userType = null;
        resolve(success);
      }, reject);
    });
  };

  static reset = (credentials: object): Promise<void> => {
    return new Promise((resolve, reject) => {
      axios.post("/send-reset", credentials).then(({ data }) => {
        setToken(data.token);
        UserService.userId = data.id;
        resolve();
      }, reject);
    });
  };

  static update = (name: string, email: string, password: string): Promise<User> => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/users/${UserService.userId}`, {
          contactName: name,
          email,
          password,
        })
        .then(({ data }) => {
          resolve(data);
        }, reject);
    });
  };

  static updatePassword = (credentials: object) => {
    return new Promise((resolve, reject) => {
      axios.post("/passwordSubmit", credentials).then((success) => {
        resolve(success);
      }, reject);
    });
  };

  static getPasswordRequirements = (): AxiosPromise<PasswordRequirements> => {
    return axios.get<PasswordRequirements>("/password-requirements");
  };
}
