import "styles/app.scss";
import "styles/global.scss";
import { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserService } from "services/UserService";
import "./i18n";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Platform from "./Platform";
import Login from "pages/Login";

function App(): JSX.Element {
  const queryClient = new QueryClient();
  const [authorized, setAuthorized] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onRender();
  }, []);

  const checkSignedIn = async () => {
    UserService.tryAuth()
      .then((res) => {
        setAuthorized(res);
      })
      .catch(() => {
        setAuthorized(false);
      });
  };
  const onRender = async () => {
    checkSignedIn().then(() => {
      setLoading(false);
    });
  };
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        {!loading &&
          (authorized ? (
            <Platform signedIn={authorized} setSignedIn={setAuthorized} />
          ) : authorized === false ? (
            <Router>
              <Switch>
                <Route exact path="/login">
                  <Login signedIn={authorized} setSignedIn={setAuthorized} />
                </Route>
                <Route path="/confirm-password-reset/*">
                  <Login reset signedIn={authorized} setSignedIn={setAuthorized} />
                </Route>
                <Route path="*">
                  <Redirect to={authorized ? "/" : "/login"} />
                </Route>
              </Switch>
            </Router>
          ) : (
            <></>
          ))}
      </div>
    </QueryClientProvider>
  );
}

export default App;
