import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Header } from "../../components";
import RecallHeader from "../../components/atoms/RecallHeader";
import StepHeader from "../../components/organisms/StepHeader";
import StickyFooter from "../../components/organisms/StickyFooter";
import ClaimBody from "../../components/organisms/ClaimBody";
import { useEffect } from "react";
import { deleteClaim, fetchClaim, fetchClaims } from "../../services/ClaimService";
import Instructions from "./Instructions";
import RecallSwitcher from "./RecallSwitcher";
import { Claim, ClaimStatus, ClaimType, isRecall } from "types/claim";
import BasicInfo from "./BasicInfo";
import ClaimItems from "./Items";
import SubmitModal from "./SubmitModal";
import RecallItems from "./RecallItems";
import RecallSubmit from "./RecallSubmit";
import Modal from "components/organisms/Modal";
import { UserService } from "services/UserService";
import { UserType } from "types";
import { Row } from "react-bootstrap";

export type ClaimComponentProps = {
  setCanProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setOnNext: React.Dispatch<React.SetStateAction<any>>;
  viewOnly: boolean;
};

const ClaimPage: React.FC<{ match }> = ({ match }) => {
  const [stage, setStage] = useState(0);
  const [data, setData] = useState<Claim>();
  const [canProgress, setCanProgress] = useState(false);
  const [type, setType] = useState(match.params.type);
  const [id, setId] = useState(match.params.id);
  const [loading, setLoading] = useState(false);
  const [viewOnly, setViewOnly] = useState(null);
  const [stay, setStay] = useState(false);
  const [editItems, setEditItems] = useState(false);
  const [handleBackToItems, setHandleBackToItems] = useState(() => async () => {});
  const [itemDone, setItemDone] = useState(false);
  const [handleItemDone, setHandleItemDone] = useState(() => async () => {});
  const [onNext, setOnNext] = useState(() => async () => {});
  const [disposition, setDisposition] = useState<"return" | "destroy">("return");
  const [dispositionDone, setDispositionDone] = useState(false);
  const [show, setShow] = useState(false);

  const defaultComponentProps = {
    setCanProgress: setCanProgress,
    setOnNext: setOnNext,
    viewOnly: viewOnly,
  };

  const handleDisposition = (value: any) => {
    setDisposition(value);
    setDispositionDone(true);
  };

  const componentMap = {
    refund: {
      stages: [
        {
          label: "Instructions",
          component: <Instructions setStay={setStay} {...defaultComponentProps} type="refund" />,
        },
        {
          label: "Basic Info",
          component: (
            <BasicInfo
              data={data}
              id={id}
              type={ClaimType.Refund}
              {...defaultComponentProps}
            ></BasicInfo>
          ),
        },
        {
          label: "Items",
          component: (
            <ClaimItems
              {...defaultComponentProps}
              type={ClaimType.Refund}
              claimId={id}
              edit={editItems}
              setEdit={setEditItems}
              setItemDone={setItemDone}
              setHandleBack={setHandleBackToItems}
              setHandleDone={setHandleItemDone}
              setStay={setStay}
            />
          ),
        },
        {
          label: "",
          component: (
            <SubmitModal
              {...defaultComponentProps}
              type={type !== "recall_qa" ? type : disposition}
              id={id}
              back={() => setStage(stage - 1)}
            />
          ),
        },
      ],
      header: "Refund Claim",
    },
    adjustment: {
      stages: [
        {
          label: "Instructions",
          component: (
            <Instructions setStay={setStay} {...defaultComponentProps} type="adjustment" />
          ),
        },
        {
          label: "Basic Info",
          component: (
            <BasicInfo
              data={data}
              id={id}
              type={ClaimType.Adjustment}
              {...defaultComponentProps}
            ></BasicInfo>
          ),
        },
        {
          label: "Items",
          component: (
            <ClaimItems
              {...defaultComponentProps}
              type={ClaimType.Adjustment}
              claimId={id}
              edit={editItems}
              setEdit={setEditItems}
              setItemDone={setItemDone}
              setHandleBack={setHandleBackToItems}
              setHandleDone={setHandleItemDone}
              setStay={setStay}
            />
          ),
        },
        {
          label: "",
          component: (
            <SubmitModal
              {...defaultComponentProps}
              type={type !== "recall_qa" ? type : disposition}
              id={id}
              back={() => setStage(stage - 1)}
            />
          ),
        },
      ],
      header: "Adjustment Claim",
    },
    recall_pr: {
      stages: [
        {
          label: "Instructions",
          component: <Instructions setStay={setStay} {...defaultComponentProps} type="recall_pr" />,
        },
        {
          label: "Information & Submit",
          component: (
            <RecallItems
              {...defaultComponentProps}
              type="recall_pr"
              id={id}
              claim={data}
              setClaim={setData}
            />
          ),
        },
        {
          label: "",
          component: (
            <SubmitModal
              {...defaultComponentProps}
              type={type !== "recall_qa" ? type : disposition}
              id={id}
              back={() => setStage(stage - 1)}
            />
          ),
        },
      ],
      header: "Wine Floor Price Product Recall Claim",
    },
    recall_qa: {
      stages: [
        {
          label: "Instructions",
          component: <Instructions setStay={setStay} {...defaultComponentProps} type="recall_qa" />,
        },
        {
          label: "Basic Info",
          component: (
            <BasicInfo
              data={data}
              id={id}
              type={ClaimType.Recall}
              setDisposition={handleDisposition}
              {...defaultComponentProps}
            ></BasicInfo>
          ),
        },
        {
          label: "Item Details",
          component: (
            <RecallItems
              {...defaultComponentProps}
              type={disposition}
              id={id}
              claim={data}
              setClaim={setData}
            />
          ),
        },
        {
          label: "Submit",
          component: (
            <RecallSubmit
              {...defaultComponentProps}
              type={disposition}
              id={id}
              claim={data}
              setClaim={setData}
            />
          ),
        },
        {
          label: "",
          component: (
            <SubmitModal
              {...defaultComponentProps}
              type={type !== "recall_qa" ? type : disposition}
              id={id}
              back={() => setStage(stage - 1)}
            />
          ),
        },
      ],
      header: "QA Alert Product Recall Claim",
    },
    recall: {
      stages: [
        {
          label: "",
          component: (
            <RecallSwitcher
              {...defaultComponentProps}
              claimId={match.params.id}
              setType={setType}
              setStay={setStay}
            />
          ),
        },
      ],
      header: "Product Recall",
    },
  };

  const getData = async () => {
    let data = await fetchClaim(match?.params?.type, match?.params?.id);
    if (isRecall(data) && data.alert?.type?.length > 0) {
      setType(data.alert.type === "price_recall" ? "recall_pr" : "recall_qa");
    }
    setViewOnly(!(data.status === "draft" || data.status === "incomplete"));
    setData(data);
  };

  const defaultOnNext = async () => {
    if (!stay) {
      setStage(stage + 1);
    } else {
      setStay(false);
    }
  };

  const subheader = () => {
    let base = `#${match.params.id}`;
    if (dispositionDone) {
      if (disposition === "destroy") base += " - Destroy Onsite";
      else base += " - Return to LCBO QA";
    }
    return base;
  };

  useEffect(() => {
    if (UserService.getType() === UserType.GrocerReporter) history.push("/reporter");
    getData();
  }, []);

  const history = useHistory();

  return (
    <div className="d-flex flex-column min-vh-100 claim-page">
      <Header variant="claim" onBack={() => (viewOnly ? history.push("/") : setShow(true))} />
      {!loading && (
        <>
          <RecallHeader header={componentMap[type].header} subHeader={subheader()} />

          <Modal
            handleClose={() => {
              setShow(!show);
            }}
            show={show}
          >
            <div className="modal-leave">
              <div className="title">Wait!</div>
              <div className="body">
                <strong>Are you sure you would like to leave this form? </strong>
              </div>
              <div className="body">
                If you would like to return to the main menu, we highly recommend you first save
                your form{data?.status !== ClaimStatus.Completed ? " into a draft" : ""}.
              </div>
              <div className="button-container">
                <Button
                  onClick={() => {
                    setShow(false);
                  }}
                  styling="back-btn"
                >
                  Return to Form
                </Button>
                <Row>
                  <div className="half-btn">
                    <Button
                      onClick={async () => {
                        onNext().then(() => {
                          history.push("/");
                        });
                      }}
                      styling="save-btn"
                    >
                      Save {data?.status !== ClaimStatus.Completed ? "to Drafts" : "Changes"}
                    </Button>
                  </div>
                  {data?.status !== ClaimStatus.Completed && (
                    <div className="half-btn">
                      <Button
                        onClick={() => {
                          deleteClaim(
                            parseInt(id),
                            ["adjustment", "refund"].includes(type) ? type : "recall"
                          );
                          history.push("/");
                        }}
                        styling="delete-btn"
                      >
                        Delete Form
                      </Button>
                    </div>
                  )}
                </Row>
              </div>
            </div>
          </Modal>

          {componentMap[type].stages[stage].label.length > 0 && (
            <StepHeader
              stages={componentMap[type].stages.map((c) => c.label)}
              currentStage={stage}
              onSelect={() => {}}
            />
          )}
          <ClaimBody>{componentMap[type].stages[stage].component}</ClaimBody>
          <StickyFooter>
            {editItems ? (
              <>
                <Button onClick={handleBackToItems} styling="back-btn">
                  Back to Items
                </Button>
                <Button
                  disabled={!itemDone || viewOnly}
                  onClick={handleItemDone}
                  styling="next-btn"
                >
                  Save Item
                </Button>
              </>
            ) : (
              <>
                {stage > 0 && (
                  <Button
                    onClick={() => {
                      setStage(stage - 1);
                    }}
                    styling="back-btn"
                  >
                    Previous
                  </Button>
                )}
                <Button
                  disabled={
                    !canProgress ||
                    (stage ===
                      componentMap[type].stages.filter(({ label }) => label?.length > 0).length -
                        1 &&
                      viewOnly)
                  }
                  onClick={() => {
                    setLoading(true);
                    onNext().then(() => {
                      defaultOnNext().then(() => {
                        setLoading(false);
                        setStay(false);
                      });
                    });
                  }}
                  styling="next-btn"
                >
                  {stage ===
                    componentMap[type].stages.filter(({ label }) => label?.length > 0).length - 1 &&
                  type !== "recall"
                    ? viewOnly
                      ? "Submitted"
                      : "Submit"
                    : "Next"}
                </Button>
              </>
            )}
          </StickyFooter>
        </>
      )}
    </div>
  );
};

export default ClaimPage;
