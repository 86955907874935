import Checkbox from "components/atoms/Checkbox";
import TextInput from "components/atoms/TextInput";
import React from "react";
import { Row, Container, Card } from "react-bootstrap";
import { Product } from "types";

type SelectableItem = Pick<Product, "itemNumber" | "itemName" | "imageUrlThumb" | "productSize"> & {
  checked: boolean;
  units: number;
};

export type SelectableItemTileProps = {
  items: SelectableItem[];
  modifyItems: (i: number, newValues: { checked?: boolean; units?: number }) => void;
  disabled?: boolean;
  loading?: boolean;
};

const SelectableItemTile: React.FC<SelectableItemTileProps> = ({
  items,
  modifyItems,
  disabled = false,
  loading = false,
}) => {
  return loading ? (
    <div>Loading...</div>
  ) : (
    <div>
      {items.map((item, idx) => (
        <div className="selectable-item-tile">
          <Container className="flex-grow-1 wrapper">
            <Card className="border-none">
              <Card.Body>
                <Row noGutters={true} className="row">
                  <div className="col-2 check">
                    <Checkbox
                      defaultValue={item.checked}
                      onChange={() =>
                        modifyItems(idx, {
                          checked: !item.checked,
                        })
                      }
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-2">
                    <img src={item.imageUrlThumb} alt="Item Thumbnail" />
                  </div>
                  <div className="col-5">
                    <div>{item.itemName}</div>
                    <div>
                      <p>{item.productSize}</p>
                    </div>
                    <div>
                      <p>Item #: {item.itemNumber}</p>
                    </div>
                  </div>
                  <div className="col-3 units">
                    <TextInput
                      type="number"
                      label="# of units"
                      onChange={(e) => {
                        modifyItems(idx, {
                          units: e.target.value,
                        });
                      }}
                      asterisk
                      disabled={disabled || !item.checked}
                      defaultValue={item.units > 0 ? item.units.toString() : ""}
                    />
                  </div>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </div>
      ))}
    </div>
  );
};

export default SelectableItemTile;
