import React, { useState } from "react";
import DropdownArrowIcon from "components/atoms/Icons/DropdownArrowIcon";

export type ExpandableProps = {
  children?: React.ReactNode;
  label: string;
  defaultValue?: boolean;
};

const Expandable: React.FC<ExpandableProps> = ({ children, label, defaultValue = false }) => {
  const [expanded, setExpanded] = useState(defaultValue);
  return (
    <div className="expandable">
      <div
        onClick={() => {
          setExpanded(!expanded);
        }}
        className="topbar"
      >
        <div>{label}</div>
        <div>
          <div>
            <div className={`icon ${expanded ? "" : "collapsed"}`}>
              <DropdownArrowIcon width="100%" height="100%" />
            </div>
          </div>
        </div>
      </div>
      <div className={`children ${expanded ? "" : "none"}`}>{children}</div>
    </div>
  );
};

export default Expandable;
