import * as React from "react";

function SvgClearInputIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="34"
      height="31"
      viewBox="0 0 34 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="12.0684"
        y1="19.2713"
        x2="21.3393"
        y2="10.0004"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <line
        x1="12.4142"
        y1="10"
        x2="21.6852"
        y2="19.271"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SvgClearInputIcon;
