import { Button } from "components";
import React, { useEffect, useState } from "react";
import { postComment } from "services/ClaimService";
import { Claim } from "types";

export type CommentProps = Pick<Claim, "id" | "type" | "userId" | "comments"> & {
  onSubmit: () => void;
};

const Comments: React.FC<CommentProps> = ({ id, type, userId, comments, onSubmit }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [comments]);

  const onChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await postComment(id, type, message);
    onSubmit();
    setMessage("");
  };

  let latestDate = "";
  return (
    <>
      <div className="comments">
        <div className="comments-list">
          {comments && comments.length > 0 ? (
            comments.map((comment) => {
              let timestamp = comment.createDate.toFormat("dd LLL yyyy");
              if (timestamp === latestDate) {
                timestamp = null;
              } else {
                latestDate = timestamp;
              }
              return (
                <>
                  <div className="timestamp">{timestamp}</div>
                  <div
                    key={comment.id}
                    className={
                      "comment " +
                      (comment.status
                        ? "status"
                        : comment.user && comment.user.id === userId
                        ? "self"
                        : "other")
                    }
                  >
                    <div className="user">
                      <strong>{comment.user.contactName + " "}</strong>
                    </div>
                    <p>
                      {comment.status ? (
                        <>
                          Marked the claim as <strong>{String(comment.status) === 'approved' ? 'accepted' : comment.status}</strong>
                        </>
                      ) : (
                        comment.message
                      )}
                    </p>
                  </div>
                </>
              );
            })
          ) : (
            <p>No updates found.</p>
          )}
        </div>
      </div>
      <div className="submit-comment">
        <form onSubmit={handleSubmit}>
          <input value={message} type="text" onChange={onChange} placeholder="Add a comment..." />
          <Button
            styling="blue-fill-btn small"
            disabled={message.trim().length === 0 || loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </form>
      </div>
    </>
  );
};
export default Comments;
