import { Adjustment, Claim, ClaimType, Recall, Refund } from "types/claim";
import { Comment } from "types/comment";
import axios from "./BaseService";
import { DateTime } from "luxon";
import {
  refundSchema,
  adjustmentSchema,
  recallSchema,
  typeCheck,
} from "./validation/claimValidators";
import { AnySchema } from "yup";
import { AdjustmentItem, Item, Product, RecallItem, RefundItem } from "types";
import { fetchClaim } from "./ClaimService";

// If lastComment undefined or null, last comment will be extracted from comments. Latest datetime found
// between updateDate and lastComment will be used.
const lastActivity = (
  updateDate: string,
  lastComment: Comment = undefined,
  comments: Comment[] = []
): DateTime => {
  let lastCommentUpdate: DateTime | undefined = undefined;
  if (!lastComment) {
    const commentUpdates: DateTime[] = comments.map((comment) =>
      DateTime.fromISO(comment.updateDate)
    );
    lastCommentUpdate = DateTime.min(...commentUpdates);
  } else {
    lastCommentUpdate = lastComment.updateDate;
  }

  if (lastCommentUpdate !== undefined) {
    return lastCommentUpdate > updateDate ? lastCommentUpdate : updateDate;
  } else {
    return updateDate;
  }
};

// Rename properties, remove extras, retype for yup validation
const preTypeCheck = (claimType: ClaimType, claim: any): any => {
  if (claimType === ClaimType.Refund || claimType === ClaimType.Adjustment) {
    let { createDate, updateDate, notification, lastComment } = claim;
    createDate = new Date(createDate);
    updateDate = new Date(updateDate);
    notification = notification === null ? false : notification;
    lastComment = lastComment === null ? {} : lastComment;
    claim = { ...claim, createDate, updateDate, notification, lastComment };
    delete claim.assignedTo;
    delete claim.date;
    return claim;
  } else if (claimType === ClaimType.Recall) {
    let { id, user: storeId, created: createDate, updated: updateDate } = claim;
    id = parseInt(id);
    storeId = storeId?.storeId;
    createDate = new Date(createDate);
    updateDate = new Date(updateDate);
    claim = { ...claim, id, storeId, createDate, updateDate };
    delete claim.user;
    delete claim.created;
    delete claim.updated;
    return claim;
  }
};

// Apply Luxon datetimes and assert types
const postTypeCheck = <T extends Claim>(claim: any): T => {
  claim.createDate = DateTime.fromJSDate(claim.createDate);
  if (claim.type === ClaimType.Recall) {
    claim.updateDate = lastActivity(
      DateTime.fromJSDate(claim.updateDate),
      undefined,
      claim.comments
    );
  } else {
    claim.updateDate = lastActivity(DateTime.fromJSDate(claim.updateDate), claim.lastComment);
  }
  return claim as T;
};

// Wrapper function for type check hooks
const applyTypeCheck = async <T extends Claim>(
  claim: T,
  claimType: ClaimType,
  schema: AnySchema
): Promise<T | undefined> => {
  claim = preTypeCheck(claimType, claim);
  claim = await typeCheck(claim, schema);
  if (claim !== undefined) {
    return postTypeCheck<T>(claim);
  } else {
    return undefined;
  }
};

export const fetchItems = async (
  claimId: Claim["id"],
  type: ClaimType
): Promise<RecallItem[] | AdjustmentItem[] | RefundItem[]> => {
  let items = [];
  if (type === ClaimType.Recall) {
    items = (await axios.get(`/alert_claims/${claimId}/items`)).data;
    return items;
  } else {
    items = (await fetchClaim(type, claimId)).items;
    return items;
  }
};

export const postItem = async (
  claimId: Claim["id"],
  type: ClaimType,
  item: Item
): Promise<RecallItem | AdjustmentItem | RefundItem> => {
  return (
    await axios.post(`/${type === ClaimType.Recall ? "alert_claim" : type}s/${claimId}/items`, {
      ...item,
    })
  ).data;
};

export const deleteItem = async (
  id: Item["id"],
  claimId: Claim["id"],
  type: ClaimType
): Promise<RecallItem | AdjustmentItem | RefundItem> => {
  return await axios.delete(
    type === ClaimType.Recall ? `/alert_claims/items/${id}` : `/${type}s/${claimId}/items/${id}`
  );
};

export const patchItem = async (
  id: Item["id"],
  claimId: Claim["id"],
  type: ClaimType,
  changes: Partial<Omit<Recall & Adjustment & Refund, "id">>
): Promise<RecallItem | AdjustmentItem | RefundItem> => {
  return (
    await axios.patch(
      type === ClaimType.Recall ? `/alert_claims/items/${id}` : `/${type}s/${claimId}/items/${id}`,
      changes
    )
  ).data;
};

export const getProductInfo = async (itemNumbers: Item["itemNumber"][]): Promise<Product[]> => {
  return (await axios.get(`/products`, { params: { ids: itemNumbers } })).data;
};
