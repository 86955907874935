import * as React from 'react';

function SvgDropdownArrowIcon(props) {
  return props.up ? (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 5.66408L5.07619 1.58789L9.15238 5.66408"
        stroke="#858585"
        strokeWidth="1.35873"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.15234 1.58787L5.07615 5.66406L0.999962 1.58787"
        stroke="#858585"
        strokeWidth="1.35873"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgDropdownArrowIcon;
