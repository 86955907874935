import React from "react";
import Stepper from "@bit/mui-org.material-ui.stepper";
import Step from "@bit/mui-org.material-ui.step";
import StepLabel from "@bit/mui-org.material-ui.step-label";

export type StepHeaderProps = {
  stages: string[];
  currentStage: number;
  onSelect: (index: number) => void;
};

const StepHeader: React.FC<StepHeaderProps> = ({ stages, currentStage, onSelect }) => {
  return (
    <div className="step-header">
      <Stepper nonLinear alternativeLabel activeStep={currentStage}>
        {stages
          .filter((label) => label?.length > 0)
          .map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={() => (
                    <div className={`circle ${currentStage === index ? "active" : ""}`}>
                      {index + 1}
                    </div>
                  )}
                >
                  <div className="label">{label}</div>
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
    </div>
  );
};

export default StepHeader;
