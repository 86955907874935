import * as React from 'react';

function SvgCheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.9998 6.70001L9.51984 15.18L5.97984 11.64C5.58984 11.25 4.95984 11.25 4.56984 11.64C4.17984 12.03 4.17984 12.66 4.56984 13.05L8.80984 17.29C9.19984 17.68 9.82984 17.68 10.2198 17.29L19.3998 8.11001C19.7898 7.72001 19.7898 7.08001 19.3898 6.69001C19.0198 6.31001 18.3898 6.31001 17.9998 6.70001Z"
        fill="#27AE60"
      />
    </svg>
  );
}

export default SvgCheckIcon;
