import React from "react";
import { Row, Container, Card } from "react-bootstrap";
import { Product } from "types";

export type ItemCarouselCardProps = Pick<
  Product,
  "itemNumber" | "itemName" | "imageUrlThumb" | "productSize"
> & { index?: number; outOf?: number };

const ItemCarouselCard: React.FC<ItemCarouselCardProps> = ({
  itemNumber,
  itemName,
  imageUrlThumb,
  productSize,
  index = -1,
  outOf = -1,
}) => {
  if (itemName.length >= 45) {
    itemName = itemName.slice(0, 42).concat("...");
  }
  return (
    <div className="item-carousel-card">
      <Container className="flex-grow-1">
        <Row>
          <Card className="border-none">
            <Card.Body>
              <Card.Title as="h6">{`${itemName} (${productSize})`}</Card.Title>
              <div className="product-image">
                <img src={imageUrlThumb} alt={itemName} />
              </div>
              <Card.Text>{`ID: #${itemNumber}`}</Card.Text>
              <div className="index">{index > 0 && outOf > 0 ? `${index}/${outOf}` : null}</div>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </div>
  );
};

export default ItemCarouselCard;
