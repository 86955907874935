import React from "react";

export type RecallHeaderProps = {
  header: string;
  subHeader: string;
};

const RecallHeader: React.FC<RecallHeaderProps> = ({ header, subHeader }) => {
  return (
    <div className="recall-header">
      <div className="header">{header}</div>
      <div className="sub-header">{subHeader}</div>
      <hr />
    </div>
  );
};

export default RecallHeader;
