import { string, number, date, boolean, object, array, AnySchema } from "yup";
import { refundItemSchema, adjustmentItemSchema, recallItemSchema } from "./itemValidators";
import { commentSchema } from "./commentValidators";
const claimSchema = object({
  id: number().defined(),
  userId: number().defined(),
  storeId: number().defined().nullable(),
  status: string().defined(),
  createDate: date().defined(),
  updateDate: date().defined(),
  comments: array().of(commentSchema),
  completedBy: string().defined().nullable(),
});

const refundSchema = claimSchema.concat(
  object({
    notification: boolean().defined(),
    items: array().of(refundItemSchema).defined(),
  })
);

const adjustmentSchema = claimSchema.concat(
  object({
    notification: boolean().defined(),
    goodsReceivedDate: string().defined().nullable(),
    bolNumber: string().defined().nullable(),
    carrierName: string().defined().nullable(),
    frozenLoad: boolean().defined().nullable(),
    sealIntact: boolean().defined().nullable(),
    sealNumber: string().defined().nullable(),
    isClaimWithDeniedItem: boolean().nullable(),
    items: array().of(adjustmentItemSchema).defined(),
  })
);

const recallSchema = claimSchema.concat(
  object({
    alert: object().defined().nullable(),
    alertId: string().defined().nullable(),
    disposition: string().defined().nullable(),
    storeOwnerName: string().defined().nullable(),
    storePhoneNumber: string().defined().nullable(),
    storeEmail: string().defined().nullable(),
    completedByTitle: string().defined().nullable(),
    items: array().of(recallItemSchema).defined(),
  })
);

const claimDraftSchema = object({
  id: number().nullable().optional(),
  userId: number().nullable().optional(),
  storeId: number().nullable().optional(),
  status: string().nullable().optional(),
  createDate: date().nullable().optional(),
  updateDate: date().nullable().optional(),
  comments: array().of(object()).nullable().optional(),
  completedBy: string().defined().nullable().optional(),
});

const refundDraftSchema = claimDraftSchema.concat(
  object({
    notification: boolean().nullable().optional(),
    items: array().of(refundItemSchema).nullable().optional(),
  })
);

const adjustmentDraftSchema = claimDraftSchema.concat(
  object({
    notification: boolean().nullable().optional(),
    goodsReceivedDate: string().nullable().optional(),
    bolNumber: string().nullable().optional(),
    carrierName: string().nullable().optional(),
    frozenLoad: boolean().nullable().optional(),
    sealIntact: boolean().nullable().optional(),
    sealNumber: string().nullable().optional(),
    isClaimWithDeniedItem: boolean().nullable().optional(),
    items: array().of(adjustmentItemSchema).nullable().optional(),
  })
);

const recallDraftSchema = claimDraftSchema.concat(
  object({
    alertId: string().nullable().optional(),
    disposition: string().nullable().optional(),
    storeOwnerName: string().defined().nullable().optional(),
    storePhoneNumber: string().defined().nullable().optional(),
    storeEmail: string().defined().nullable().optional(),
    completedByTitle: string().defined().nullable().optional(),
    items: array().of(recallItemSchema).nullable().optional(),
  })
);

const typeCheck = async (claim: any, schema: AnySchema): Promise<any | undefined> => {
  try {
    await schema.validate(claim, { strict: true });
    return claim;
  } catch (err) {
    console.log(err.errors[0]);
    console.log(claim);
    return undefined;
  }
};

export {
  refundSchema,
  adjustmentSchema,
  recallSchema,
  refundDraftSchema,
  adjustmentDraftSchema,
  recallDraftSchema,
  typeCheck,
};
