import React, { useRef } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import TooltipIcon from "./Icons/TooltipIcon";

export type InfoTooltipProps = {
  type: string;
};

const popoverFocus = (type) => {
  const title =
    type === "cases" ? (
      <div className="tooltip-title">Enter full cases only.</div>
    ) : (
      <div className="tooltip-title">
        Enter individual selling units, i.e. one bottle or one 6pk.
      </div>
    );
  return (
    <Popover id="popover-trigger-focus" title="Popover bottom" show>
      <div className="tooltip-container">
        {title}
        <div className="tooltip-content">
          Units and Cases Claimed are mutually exclusive; do not duplicate quantities in both
          fields.
        </div>
        <div className="tooltip-content">
          <strong>Example:</strong> Report 1 case of a product containing 24 selling units as 24
          units <strong>OR</strong> 1 case; <strong>do not</strong> enter in both fields
        </div>
        <div className="tooltip-content">
          <strong>Example:</strong> Report 1.5 cases of a product containing 4 selling units as 6
          units <strong>OR</strong> 1 case <strong>and</strong> 2 units
        </div>
      </div>
    </Popover>
  );
};
const InfoTooltip: React.FC<InfoTooltipProps> = ({ type }) => {
  const buttonRef = useRef(null);
  return (
    <div className="info-tooltip">
      <OverlayTrigger trigger="focus" placement="right" overlay={popoverFocus(type)}>
        <Button ref={buttonRef} onClick={() => buttonRef.current.focus()}>
          <TooltipIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default InfoTooltip;
