import * as React from 'react';

function SvgSearchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.9085 15.7332L12.2751 11.0999C13.2168 9.80825 13.6335 8.11658 13.0918 6.31658C12.5251 4.45825 10.9501 2.99991 9.05013 2.61658C5.17513 1.83325 1.83347 5.18325 2.6168 9.04991C3.00013 10.9582 4.45847 12.5332 6.3168 13.0916C8.1168 13.6332 9.80847 13.2166 11.1001 12.2749L15.7335 16.9082C16.0585 17.2332 16.5835 17.2332 16.9085 16.9082C17.2335 16.5832 17.2335 16.0582 16.9085 15.7332ZM4.1668 7.91658C4.1668 5.84158 5.8418 4.16658 7.9168 4.16658C9.9918 4.16658 11.6668 5.84158 11.6668 7.91658C11.6668 9.99158 9.9918 11.6666 7.9168 11.6666C5.8418 11.6666 4.1668 9.99158 4.1668 7.91658Z"
        fill="#272727"
      />
    </svg>
  );
}

export default SvgSearchIcon;
