import React from "react";

export type CheckboxProps = {
  onChange: (checked: any) => void;
  defaultValue?: boolean;
  disabled?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  defaultValue = false,
  disabled = false,
}) => {
  return (
    <div className="check-box-div">
      <input
        className="check-box"
        type="checkbox"
        defaultChecked={defaultValue}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default Checkbox;
