import * as React from "react";

function SvgHamburgerIcon(props: React.SVGProps<SVGSVGElement>) {
  const color = props.color ? props.color : "#575757";
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 8H5C3.9 8 3 7.1 3 6C3 4.9 3.9 4 5 4H19C20.1 4 21 4.9 21 6C21 7.1 20.1 8 19 8ZM19 10H5C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14H19C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM19 16H5C3.9 16 3 16.9 3 18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18C21 16.9 20.1 16 19 16Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgHamburgerIcon;
